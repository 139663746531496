import ApplicationShell from "../../Components/ApplicationShell"
import { Fragment, useState, useEffect } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import SlideOvers from "../../Components/SlideOvers"
import GlobalVars from "../../Config"

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
  
export default function RecurringJob() {
    document.title = "週期性工作";

    // check if there's url argument for id
    const [id, setId] = useState(null);
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        setId(urlParams.get('id'));
    }, []);


    // slide over open/close state
    const [slideOverOpen, setSlideOverOpen] = useState(false);

    return (
        <>
            <ApplicationShell>
                <PageHeadings />
                <ScheduleList />
            </ApplicationShell>
            <RecurringJobSlideOver open={slideOverOpen} setOpen={setSlideOverOpen} id={id} />
        </>
    )
}

// page headings
function PageHeadings() {
    return (
      <div className="md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            週期性工作
          </h2>
        </div>
        <div className="mt-4 flex md:ml-4 md:mt-0">
          {/* <button
            type="button"
            className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Edit
          </button> */}
          {/* <button
            type="button"
            className="ml-3 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            新增
          </button> */}
        </div>
      </div>
    )
  }

  
// recurring schedule list
const statuses = {
    Complete: 'text-green-700 bg-green-50 ring-green-600/20',
    'In progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
    Archived: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
  }
const scheduleList = [
{
    id: 1,
    name: 'GraphQL API',
    href: '#',
    status: 'Complete',
    createdBy: 'Leslie Alexander',
    dueDate: 'March 17, 2023',
    dueDateTime: '2023-03-17T00:00Z',
},
{
    id: 2,
    name: 'New benefits plan',
    href: '#',
    status: 'In progress',
    createdBy: 'Leslie Alexander',
    dueDate: 'May 5, 2023',
    dueDateTime: '2023-05-05T00:00Z',
},
{
    id: 3,
    name: 'Onboarding emails',
    href: '#',
    status: 'In progress',
    createdBy: 'Courtney Henry',
    dueDate: 'May 25, 2023',
    dueDateTime: '2023-05-25T00:00Z',
},
{
    id: 4,
    name: 'iOS app',
    href: '#',
    status: 'In progress',
    createdBy: 'Leonard Krasner',
    dueDate: 'June 7, 2023',
    dueDateTime: '2023-06-07T00:00Z',
},
{
    id: 5,
    name: 'Marketing site redesign',
    href: '#',
    status: 'Archived',
    createdBy: 'Courtney Henry',
    dueDate: 'June 10, 2023',
    dueDateTime: '2023-06-10T00:00Z',
},
] 
function ScheduleList() {
    const [scheduleList, setScheduleList] = useState([]);

    // create fetch request to /api/v1/jobs/recurring-schedule to grab all recurring schedules
    useEffect(() => {
        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/jobs/recurring-schedule?organizationID=" + localStorage.getItem("organizationId");
        
        fetch(url)
        .then(response => response.json())
        .then(rawData => {
            let data = rawData.schedules;
            // setScheduleList(data);

            // create a name field for each recurring schedule <locationName> - <typeName>
            data.forEach(element => {
                if (element.jobDetail.locationName === 'More than one location') {
                    element.name = "多個地點 - ";
                } else {
                    element.name = element.jobDetail.locationName + " - ";
                }

                if (element.jobDetail.typeName === 'More than one type') {
                    element.name += "多個工作類型";
                } else {
                    element.name += element.jobDetail.typeName;
                }

            });

            // map recurring schedule frequency to chinese 
            data.forEach(element => {
                let recurringSchedule = "";
                if (element.recurringSchedule['frequency'] === "daily") {
                    recurringSchedule = "每日";
                } else if (element.recurringSchedule['frequency'] === "weekly") {
                    recurringSchedule = "每週";
                } else if (element.recurringSchedule['frequency'] === "monthly") {
                    recurringSchedule = "每月";
                } else if (element.recurringSchedule['frequency'] === "yearly") {
                    recurringSchedule = "每年";
                } else if (element.recurringSchedule['frequency'] === "every-two-day") {
                    recurringSchedule = "隔天";
                } else if (element.recurringSchedule['frequency'] === "every-two-week") {
                    recurringSchedule = "隔週";
                }
                element.recurringSchedule.freqName = recurringSchedule;
            });

            // if frequency equals to weekly, map the day of week to chinese. if equal to monthly, map the day of month to chinese
            data.forEach(element => {
                if (element.recurringSchedule['frequency'] === "weekly") {
                    let dayOfFreqName = "星期";
                    // go through element within dayOfFreq
                    element.recurringSchedule['dayOfFreq'].forEach(day => {
                        if (day === "monday") {
                            dayOfFreqName += "一, ";
                        } else if (day === "tuesday") {
                            dayOfFreqName += "二, ";
                        } else if (day === "wednewsday") {
                            dayOfFreqName += "三, ";
                        } else if (day === "thursday") {
                            dayOfFreqName += "四, ";
                        } else if (day === "friday") {
                            dayOfFreqName += "五, ";
                        } else if (day === "saturday") {
                            dayOfFreqName += "六, ";
                        } else if (day === "sunday") {
                            dayOfFreqName += "日, ";
                        }
                    });
                    element.recurringSchedule.dayOfFreqName = dayOfFreqName.slice(0, -2);
                } else if (element.recurringSchedule['frequency'] === "monthly") {
                    let dayOfFreqName = "每月";
                    element.recurringSchedule['dayOfFreq'].forEach(day => {
                        dayOfFreqName += day + "日, ";
                    }); 
                    element.recurringSchedule.dayOfFreqName = dayOfFreqName.slice(0, -2);
                }
            });

            console.log(data);
            setScheduleList(data);

        })
        .catch(error => {
            console.log(error);
        });
    }, [])

    // handle deletion 
    const handleDelete = (id) => {
        console.log("delete " + id);
        // prompt user for confirmation
        if (!window.confirm("確定要刪除嗎？")) {
            return;
        }

        // fetch request to delete the recurring schedule
        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/jobs/recurring-schedule";
        let data = {
            scheduleID: id
        }
        fetch(url, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(response => {
            console.log(response);
            if (response.status === 200) {
                alert("刪除成功");
                window.location.reload();
            } else {
                alert("刪除失敗");
            }
        }).catch(error => {
            console.log(error);
            alert("刪除失敗");
        });
    }

    return (
        <ul role="list" className="divide-y divide-gray-100">
            {scheduleList.map((item) => (
                <li key={item.id} className="flex items-center justify-between gap-x-6 py-5">
                <div className="min-w-0">
                    <div className="flex items-start gap-x-3">
                    <p className="text-sm font-semibold leading-6 text-gray-900">{item.name}</p>
                    {/* <p
                        className={classNames(
                        statuses[item.status],
                        'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
                        )}
                    >
                        {item.status}
                    </p> */}
                    </div>
                    <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                    <p className="whitespace-nowrap">
                        {item.recurringSchedule.freqName} {(item.recurringSchedule.frequency == 'weekly' || item.recurringSchedule.frequency == 'every-two-week' || item.recurringSchedule.frequency == 'monthly') && ("| " + item.recurringSchedule.dayOfFreqName)} | {item.recurringSchedule.timeOfDay}
                    </p>
                    {/* <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                        <circle cx={1} cy={1} r={1} />
                    </svg>
                    <p className="truncate">Created by {item.createdBy}</p> */}
                    </div>
                </div>
                <div className="flex flex-none items-center gap-x-4">
                    {/* <a
                    href={"/recurring-job?id=" + item._id}
                    className="cursor-pointer hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                    >
                    查看詳細<span className="sr-only">, {item.name}</span>
                    </a> */}
                    <Menu as="div" className="relative flex-none">
                    <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                        <span className="sr-only">Open options</span>
                        <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                    </Menu.Button>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                        {/* <Menu.Item>
                            {({ active }) => (
                            <a
                                href="#"
                                className={classNames(
                                active ? 'bg-gray-50' : '',
                                'block px-3 py-1 text-sm leading-6 text-gray-900'
                                )}
                            >
                                Edit<span className="sr-only">, {item.name}</span>
                            </a>
                            )}
                        </Menu.Item> */}
                        {/* <Menu.Item>
                            {({ active }) => (
                            <a
                                href="#"
                                className={classNames(
                                active ? 'bg-gray-50' : '',
                                'block px-3 py-1 text-sm leading-6 text-gray-900'
                                )}
                            >
                                Move<span className="sr-only">, {item.name}</span>
                            </a>
                            )}
                        </Menu.Item> */}
                        <Menu.Item>
                            {({ active }) => (
                            <a
                                onClick={() => handleDelete(item._id)}
                                className={classNames(
                                active ? 'bg-gray-50' : '',
                                'block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer'
                                )}
                            >
                                刪除<span className="sr-only">, {item.name}</span>
                            </a>
                            )}
                        </Menu.Item>
                        </Menu.Items>
                    </Transition>
                    </Menu>
                </div>
                </li>
            ))}
        </ul>
    )

}

// New/Edit Recurring Job Slide Over
function RecurringJobSlideOver({open, setOpen, id}) {
    const [title, setTitle] = useState("新增週期性工作");

    const submitAction = () => {
        console.log("submit");
    };

    return (
        <SlideOvers open={open} setOpen={setOpen}  />
    )
}
