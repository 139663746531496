/* global NDEFReader */
import ApplicationShell from "../../Components/ApplicationShell"
import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import GlobalVars from "../../Config";
import StatusModals from "../../Components/StatusModals/StatusModals";
import SlideOvers from "../../Components/SlideOvers/SlideOvers";

import { Fragment } from 'react'
import { Menu, Transition, Dialog } from '@headlessui/react'
import { EllipsisVerticalIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid'
import { 
  CheckCircleIcon,
  XMarkIcon,
  CheckBadgeIcon,
} from "@heroicons/react/24/outline"

import { MultiSelect } from "react-multi-select-component";

import { 
  DataSheetGrid, 
  checkboxColumn,
  textColumn,
  keyColumn,
} from "react-datasheet-grid";

import "./NFCJobProgress.css";

const statuses = {
    active: 'text-green-700 bg-green-50 ring-green-600/20',
    'In progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
    inactive: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
  }

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

// job rule list 
function JobRuleList({openSlide, setSelectedJobId, setSelectedStatus, setFormTitle, setEditMode, setSelectedRuleId, setShowNotification}) {
    const [ruleList, setRuleList] = useState([]);
    
    // handle deactivation of job rule
    const handleDeactivate = (event) => {
        // confirm users for deactivating job rule
        if (!window.confirm("確定要停用此工作更改規則嗎？")) {
            return;
        }

        // fetch request to /api/v1/job-rules to deactivate job rule
        fetch(GlobalVars.BACKEND_DOMAIN + "/api/v1/job-rules", {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "ruleID": event.target.dataset.ruleId
            })
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            alert("停用成功");

            // redirect to nfc job progress page
            window.location.href = "/nfc-job-progress";
        })
        .catch(error => {
            console.log(error);
            alert("停用失敗");
        });
      };


    // fetch rule list from backend
    useEffect(() => {
        fetch(GlobalVars.BACKEND_DOMAIN + "/api/v1/job-rules?organizationID=" + localStorage.getItem("organizationId"))
        .then(response => response.json())
        .then(data => {
            setRuleList(data.data);
        })
    }, []);

    // when users click on edit rule
    const EditRule = (event) => {
      // set edit mode to true
      setEditMode(true);

      // fetch rule list from backend
      fetch(GlobalVars.BACKEND_DOMAIN + "/api/v1/job-rules?ruleID=" + event.target.dataset.ruleId)
      .then(response => response.json())
      .then(rawData => {
          let data = rawData.data;

          // open slide over 
          openSlide(true);

          // set form title
          setFormTitle("修改工作更改規則");

          // set rule id
          setSelectedRuleId(data._id);

          // set job id
          setSelectedJobId(data.jobID);

          // set target progress
          setSelectedStatus(data.targetProgress);
      })

    };

    // handle copy of rule link by copying the link to clipboard
    const handleCopy = (event) => {
      var url;
      if (event.target.dataset.location) {
        let location = event.target.dataset.location;
        url = window.location.origin + "/automatic-rule-progress-by-location/" + location;
      } else {
        var jobID = event.target.dataset.ruleId;
        url = window.location.origin + "/automatic-rule-progress/" + jobID;
      }
      navigator.clipboard.writeText(url).then(function() {
        console.log('Copying to clipboard was successful!');
        setShowNotification(true);
      }, function(err) {
        console.error('Could not copy text: ', err);
      });

    }

    return (
        <ul role="list" className="divide-y divide-gray-100">
          {ruleList.map((ruleItem) => (
            <li key={ruleItem._id} className="flex items-center justify-between gap-x-6 py-5">
              <div className="min-w-0">
                {ruleItem.jobID ? (
                  <>
                    <div className="flex items-start gap-x-3">
                      <a className="text-sm font-semibold leading-6 text-gray-900 hover:underline cursor-pointer duration-300" href={`/jobs/${ruleItem.jobID}`} target="_blank">工作編號: {ruleItem.jobID}</a>
                      <p
                        className={classNames(
                          statuses[ruleItem.status],
                          'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
                        )}
                      >
                        {ruleItem.status}
                      </p>
                    </div>
                    <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                      <p className="whitespace-nowrap">
                        修改狀態 <span className="font-medium text-gray-900">{ruleItem.targetProgress}</span>
                      </p>
                      <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                        <circle cx={1} cy={1} r={1} />
                      </svg>
                      <a className="truncate">規則編號 {ruleItem._id}</a>
                    </div>
                  </>              
                ): (  // location based rule
                  <>
                    {/* display location id */}
                    <div className="flex items-start gap-x-3">
                      <p className="text-sm font-semibold leading-6 text-gray-900">地點: {ruleItem.locationName}</p>
                      {ruleItem.status === "inactive" ? (
                        <p
                          className={classNames(
                            statuses[ruleItem.status],
                            'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
                          )}
                        >
                          {ruleItem.status}
                        </p>
                      ) : (
                        <p
                          className={classNames(
                            statuses["active"],
                            'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
                          )}
                        >
                          active
                        </p>
                      )}
                    </div>
                  </>
                )}
              </div>
              <div className="flex flex-none items-center gap-x-4">
                {/* <a
                  href={ruleItem.href}
                  className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                >
                  View Item<span className="sr-only">, {ruleItem.name}</span>
                </a> */}
                <Menu as="div" className="relative flex-none">
                  <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                    <span className="sr-only">Open options</span>
                    <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-50 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                    <Menu.Item>
                        {({ active }) => (
                          <button
                            href="#"
                            className={classNames(
                              active ? 'bg-gray-50' : '',
                              'block px-3 py-1 text-sm leading-6 text-gray-900 w-full text-left'
                            )}
                            onClick={handleCopy}
                            data-rule-id={ruleItem._id}
                            data-location={ruleItem.location}
                          >
                            複製連結
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            href="#"
                            className={classNames(
                              active ? 'bg-gray-50' : '',
                              'block px-3 py-1 text-sm leading-6 text-gray-900 w-full text-left'
                            )}
                            onClick={(e) => EditRule(e)}
                            data-rule-id={ruleItem._id}
                          >
                            修改<span className="sr-only">, {ruleItem.name}</span>
                          </button>
                        )}
                      </Menu.Item>
                      
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={classNames(
                              active ? 'bg-gray-50' : '',
                              'block px-3 py-1 text-sm leading-6 text-gray-900 w-full text-left'
                            )}
                            onClick={handleDeactivate}
                            data-rule-id={ruleItem._id}
                          >
                            刪除<span className="sr-only">, {ruleItem.name}</span>
                          </button>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>{/* <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? 'bg-gray-50' : '',
                              'block px-3 py-1 text-sm leading-6 text-gray-900'
                            )}
                          >
                            Move<span className="sr-only">, {ruleItem.name}</span>
                          </a>
                        )}
                      </Menu.Item> */}
              </div>
            </li>
          ))}
        </ul>
      )
}

// create tags using NFC API
function CreateTag({selectedTab}) {
    let reader = new NDEFReader();

    // if it's a location tab
    if (selectedTab[0].current) {
        let url = "https://example.com";
        let message = {
            records: [{ recordType: "url", data: url }]
        };

        reader.write(message);

        reader.write(message).then(() => {
            console.log("Data successfully written.");
        }).catch((error) => {
            console.error("Could not write data to the NFC tag.", error);
        });
    }

}

// new job rule form 
function NewJobRuleForm({
  selectedJobId, 
  setSelectedJobId, 
  selectedStatus, 
  setSelectedStatus, 
  editMode, 
  selectedRuleId,
  selectedLocationName,
  setSelectedLocationName,
  selectedTab,
  setSelectedTab,
}) {
    
    
    const [showValidation, setShowValidation] = useState(false);
    const [error, setError] = useState(false);

    // search for job id
    const handleSearch = (event) => {
        // set value 
        setSelectedJobId(event.target.value);

         // return if less than 24 characters
        if (event.target.value.length < 24) {
            // reset show validation
            setShowValidation(false);

            return;
        }

        console.log("searching for " + event.target.value);

        // fetch request to /api/v1/jobs to check if job id exists
        fetch(GlobalVars.BACKEND_DOMAIN + "/api/v1/jobs?jobID=" + event.target.value,{
          headers: {
            'Authorization': sessionStorage.getItem('idToken')
          }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("Job not found");
                }
                return response.json();
            })
            .then(data => { 
                console.log(data);
                setShowValidation(true);
                setError(false);
            })
            .catch(error => {
                console.log(error);
                setShowValidation(true);
                setError(true);
            });

    };

    const handleSelectJobId = (jobId) => {
        setSelectedJobId(jobId);
    };

    // when users select location tab
    if (selectedTab[0].current) {
      
      return (
        <>
          <FormNavTabs tabs={selectedTab} setTabs={setSelectedTab} />
          <NewLocationForm 
            locationName={selectedLocationName}
            setLocationName={setSelectedLocationName}
          />
        </>
      )
    } else {

      return (
        <>  
            <FormNavTabs tabs={selectedTab} setTabs={setSelectedTab} />
            <div>
              <label htmlFor="rule-id" className="block text-sm font-medium text-gray-700">
                搜尋規則編號
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="rule-id"
                  id="rule-id"
                  className={`block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md ${editMode ? '' : 'bg-gray-100'}`}
                  placeholder="規則編號"
                  value={selectedRuleId}
                  disabled={!editMode}
                />
              </div>
            </div>
            <div className="mt-4">
                <label 
                htmlFor="search"
                className="block text-sm font-medium text-gray-700"
                >搜尋工作編號</label>
                <div>
                    <div className="relative mt-2 rounded-md shadow-sm">
                    <input
                    type="text"
                    name="jobID"
                    id="jobID"
                    className={`block w-full rounded-md border-0 py-1.5 pr-10  ring-1 ring-inset  ${showValidation && error ? 'ring-red-300 placeholder:text-red-300 text-red-900 focus:ring-red-500' : 'ring-gray-300 placeholder:text-gray-300 text-gray-900 focus:ring-gray-500'} focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6`}
                    aria-invalid="true"
                    onChange={(event) => handleSearch(event)}
                    value={selectedJobId}
                    />
                    {showValidation && (
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            {error ? (
                                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                            ) : (
                                <CheckCircleIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
                            )}
                        </div>
                    )}
                    </div>
                    {showValidation && (
                        error ? (
                            <p className="mt-2 text-sm text-red-600" id="email-error" >
                                不是有效的工作編號
                            </p>
                        ) : (
                            <p className="mt-2 text-sm text-green-600" id="email-success" >
                                正確的工作編號
                            </p>
                        )
                    )}
                </div>
            </div>
            <div className="mt-4">
                <label htmlFor="targetProgress" className="block text-sm font-medium leading-6 text-gray-900">
                    掃描後的狀態
                </label>
                <select
                    id="targetProgress"
                    name="targetProgress"
                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    defaultValue="Canada"
                    onChange={(event) => setSelectedStatus(event.target.value)}
                    value={selectedStatus}
                >
                    <option value="新工作">新工作</option>
                    <option value="待確認">待確認</option>
                    <option value="未開始">未開始</option>
                    <option value="進行中">進行中</option>
                    <option value="完成">完成</option>
                    <option value="中斷">中斷</option>
                    <option value="待續">待續</option>
                    <option value="待檢查">待檢查</option>
                    <option value="待批准">待批准</option>
                    <option value="拒絕">拒絕</option>
                    <option value="取消">取消</option>
                    <option value="延期">延期</option>
                    <option value="回公司">回公司</option>
                    <option value="緊急">緊急</option>
                    <option value="維修中">維修中</option>
                    <option value="需要額外部件">需要額外部件</option>
                    <option value="需要專家幫助">需要專家幫助</option>
                </select>
            </div>
            <div className="mt-4">
              <button
                className="inline-flex items-center px-4 py-2 border border-transparent text-xs font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 hover:bg-indigo-300 duration-300"
              >
                創建標籤
              </button>
            </div>
        </>
    );
    }

    
}

// form nav tabs
function FormNavTabs({tabs, setTabs}) {

  return (
    <div
      className="mb-4"
    >
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          defaultValue={tabs.find((tab) => tab.current).name}
          onChange={(event) => {
            setTabs((tabs) =>
              tabs.map((tab) => ({
                ...tab,
                current: tab.name === event.target.value
              }))
            )
          }}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <a
                key={tab.name}
                href={tab.href}
                className={classNames(
                  tab.current
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
                )}
                aria-current={tab.current ? 'page' : undefined}
                onClick={() => {
                  setTabs((tabs) =>
                    tabs.map((t) => ({
                      ...t,
                      current: t.name === tab.name
                    }))
                  )
                }}
              >
                {tab.name}
              </a>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}

// new location form 
function NewLocationForm({locationName, setLocationName}) {
  const [options, setOptions] = useState([]);
  
  // query location list from backend
  useEffect(() => {
    fetch(GlobalVars.BACKEND_DOMAIN + "/api/v1/locations?organizationID=" + localStorage.getItem("organizationId"),{
      headers: {
        'Authorization': sessionStorage.getItem('idToken')
      }
    })
    .then(response => response.json())
    .then(rawData => {
        let data = rawData['locations']

        let options = [];
        data.forEach((location) => {
          options.push({
            value: location._id,
            label: location.name
          });
        });

        setOptions(options);
    })
  }, []);

  // update hint text 
  const [hintText, setHintText] = useState("");
  useEffect(() => {
    if (locationName.length > 0) {
      setHintText("已選擇" + locationName.length + "個地點");
    }
  }, [locationName]);


  return (
    <>
      <div>
        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
          地點名稱
        </label>
        <div className="mt-2">
          <MultiSelect
            id="locationName"
            name="locationName"
            options={options}
            className="basic-single"
            classNamePrefix="select"
            onChange={(option) => setLocationName(option)}
            value={locationName}
          />
        </div>
        <p className="mt-2 text-sm text-gray-500" id="email-description">
          {hintText}
        </p>
      </div>
    </>
  )

}

// floating message that will disappear after a few seconds
function FloatingMessage({show, setShow}) {
  // auto hide notification after 5 seconds
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        setShow(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [show, setShow]);

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-center">
                  <div className="flex w-0 flex-1 justify-between items-center">
                    <CheckBadgeIcon className="h-6 w-6 text-green-400 mr-2" aria-hidden="true" />
                    <p className="w-0 flex-1 text-sm font-medium text-gray-900">連結已複製</p>
                    {/* <button
                      type="button"
                      className="ml-3 flex-shrink-0 rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Undo
                    </button> */}
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        setShow(false)
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  )
}

// NFC Installation Slide Over 
function NFCInstallationSlideOver({open, setOpen}) {
  const [selectedTab, setSelectedTab] = useState("新增");
  const [newDeploymentName, setNewDeploymentName] = useState("NFC 安裝 " + new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString());
  const [selectedLoc, setSelectedLoc] = useState([]);

  const handleSave = () => {
    if (selectedTab === '新增') {
      // make sure there's at least one location selected
      if (selectedLoc.length === 0) {
        alert("請選擇至少一個地點");
        return;
      }

      let data = {
        "location": selectedLoc,
        "organizationID": localStorage.getItem("organizationId"),
        "status": "active",
        "name": newDeploymentName,
      };

      let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/nfc-location-deployment";
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      })
        .then(response => response.json())
        .then(data => {
          console.log(data);
          alert("新增成功");
          setSelectedTab("安裝");
          
        })

    }

  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <div className="flex h-full flex-col divide-y overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            安裝NFC
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={() => setOpen(false)}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1">
                        <NFCInstallationContent 
                          selectedTab={selectedTab} 
                          setSelectedTab={setSelectedTab} 
                          selectedLoc={selectedLoc}
                          setSelectedLoc={setSelectedLoc}
                          newDeploymentName={newDeploymentName}
                          setNewDeploymentName={setNewDeploymentName}
                        />  
                      </div>
                      
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                        onClick={() => setOpen(false)}
                      >
                        取消
                      </button>
                      <button
                        type="button"
                        onClick={handleSave}
                        className="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                      >
                        儲存
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

// NFC Installation Content
function NFCInstallationContent({
  selectedTab, 
  setSelectedTab, 
  selectedLoc, 
  setSelectedLoc,
  newDeploymentName,
  setNewDeploymentName
}) {
  const [tabs, setTabs] = useState([
    { name: '新增', current: true },
    { name: '安裝', current: false },
  ]);

  // get valid location list 
  const [locList, setLocList] = useState([]);
  useEffect(() => {
    if (selectedTab === '新增') {
      fetch(GlobalVars.BACKEND_DOMAIN + "/api/v1/job-rules?organizationID=" + localStorage.getItem("organizationId"))
      .then(response => response.json())
      .then(data => {
        if (data.hasOwnProperty('data')) {
          console.log(data);
          const activeLocations = data.data.filter(item => item.status != 'inactive' && item.hasOwnProperty('location'));
          
          // set label and value for each location
          activeLocations.forEach((location) => {
            location.label = location.locationName;
            location.value = location.location;
          });

          setLocList(activeLocations);
        }
      })
    }
    
  }, [selectedTab]);

  // get existing deployment list
  const [deploymentList, setDeploymentList] = useState([]);
  useEffect(() => {
    RetrieveDeployList({setDeploymentList});
  }, []);
  


  return (
    <>
      <div className="border-b border-gray-200">
        <div className="px-6">
          <nav className="-mb-px flex space-x-6">
            {tabs.map((tab) => (
              <a
                key={tab.name}
                onClick={() => {
                  setSelectedTab(tab.name);
                  setTabs((tabs) =>
                    tabs.map((t) => ({
                      ...t,
                      current: t.name === tab.name
                    }))
                  )
                }}
                className={classNames(
                  tab.current
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium cursor-pointer'
                )}
              >
                {tab.name}
              </a>
            ))}
          </nav>
        </div>
      </div>
      <div
          className="mt-6 flex-1 px-6"
        >
          {selectedTab == '新增' ? (
            <>
              <CreateNewNFCTagDeployment
                locList={locList} 
                selectedLoc={selectedLoc}
                setSelectedLoc={setSelectedLoc}
                deploymentName={newDeploymentName}
                setDeploymentName={setNewDeploymentName}
              />
              
            </>
          ) : (
            <>
              <SelectDeploymentList deploymentList={deploymentList} setDeploymentList={setDeploymentList} selectedLoc={selectedLoc} setSelectedLoc={setSelectedLoc} />
            </>
          )}
          
      </div>
    </>
  )
}

function RetrieveDeployList({setDeploymentList}) {
  let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/nfc-location-deployment?organizationID=" + localStorage.getItem("organizationId");
  fetch(url)
    .then(response => response.json())
    .then(data => {
      console.log(data);
      setDeploymentList(data.deployments);
    })

}

// Create new NFC tag location Deployment List
function CreateNewNFCTagDeployment({locList, selectedLoc, setSelectedLoc, deploymentName, setDeploymentName}) {

  return (
    <>
      <>
        {/* name of the deployment */}
        <label htmlFor="locationName" className="block text-sm font-medium leading-6 text-gray-900">
          安裝名稱
        </label>
        <div className="mt-2">
          <input
            type="text"
            name="locationName"
            id="locationName"
            className="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
            placeholder="輸入名稱"
            value={deploymentName}
            onChange={(event) => setDeploymentName(event.target.value)}
          />
        </div>
      </>

      <div
        className="mt-6"
      >
        {/* location name */}
        <label htmlFor="locationName" className="block text-sm font-medium leading-6 text-gray-900">
          選擇地點
        </label>
        <MultiSelect 
          options={locList}
          value={selectedLoc}
          onChange={setSelectedLoc}
        />
        {selectedLoc.length > 0 && (  
          <>
            <div>
              <p className="text-sm text-gray-500 mt-3" >
                已選擇 {selectedLoc.length} 個地點
              </p>
            </div>
            {/* list out selected area */}
            <ul role="list" className="divide-y divide-gray-200">
              {selectedLoc.map((location) => (
                <li 
                  key={location.value} 
                  className="flex items-center justify-between py-2 "
                >
                  <div 
                    className="flex items-center justify-center p-2 bg-slate-500 rounded-md w-full"
                  >
                    <p className="text-sm font-medium text-white truncate">{location.label}</p>
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
      
    </>
  )
}

const ButtonComponent = React.memo(
  ({
    active,
    rowData,
    setRowData,
    focus,
    stopEditing,
    columnData,
  }) => {
    const ref = useRef(null)

    useLayoutEffect(() => {
      if (focus) {
        ref.current?.focus()
      } else {
        ref.current?.blur()
      }
    }, [focus])

    const [btnText, setBtnText] = useState("複製連結");

    return (
      <button
        ref={ref}
        style={{
          flex: 1,
          alignSelf: 'stretch',
          pointerEvents: focus ? undefined : 'none',
        }}
        disabled={columnData.disabled}
        onClick={(e) => {
          navigator.clipboard.writeText(rowData);
          setBtnText("已複製");
          e.target.style.backgroundColor = "#60fb66";
          setTimeout(() => {
            setBtnText("複製連結");
            e.target.style.backgroundColor = "white";
          }, 1500);
          stopEditing();
        }}
      >
        {btnText}
      </button>
    )
  }
)

// button component
const buttonColumn = (options) => ({
  component: ButtonComponent,
  columnData: options,
  disableKeys: true,
  keepFocus: true,
  disabled: options.disabled,
  deleteValue: () => null,
  copyValue: ({ rowData }) => rowData,
  pasteValue: ({ value }) => value,
});

// Select deployment list and show location list 
function SelectDeploymentList({deploymentList, setDeploymentList, selectedLoc, setSelectedLoc}) {
  const [selectedDeployment, setSelectedDeployment] = useState("");
  const [selectedDeploymentLocList, setSelectedDeploymentLocList] = useState([]);
  useEffect(() => {
    // retrieve location list based on selected deployment
    if (selectedDeployment) {
      // loop through deploymentList to find the selected deployment
      deploymentList.forEach((deployment) => {
        if (deployment._id === selectedDeployment) {
          let locationList = deployment.location;
          
          locationList.forEach((location) => {
            location.locationID = location.location; // convert _id to locationID
            // check if it is localhost
            if (window.location.hostname === "localhost") {
              location.nfcUrl = "http://" + window.location.hostname + ":3000/automatic-rule-progress-by-location/" + location.location; // create nfc url
            } else {
              location.nfcUrl = "https://" + window.location.hostname + "/automatic-rule-progress-by-location/" + location.location; // create nfc url
            }
            
            // delete _id
            delete location._id;
          });

          setSelectedDeploymentLocList(locationList);

        }
      });
    }
  }, [selectedDeployment]);

  const columns = [
    { ...keyColumn('deploymentStatus', checkboxColumn), title: '狀態', maxWidth: 100 },
    { ...keyColumn('locationName', textColumn), title: '名稱', maxWidth: 200, disabled: true },
    { ...keyColumn('nfcUrl', buttonColumn({disabled: false})), title: '複製連結', maxWidth: 200, nextRow: false},
    { ...keyColumn('nfcUrl', textColumn), title: 'NFC URL', maxWidth: 200},
    { ...keyColumn('locationID', textColumn), title: '地點編號', maxWidth: 200, disabled: true },
  ];

  // auto save location list when the selectedDeploymentLocList changes
  const [lastUpdated, setLastUpdated] = useState("");
  useEffect(() => {
    // return if selectedDeploymentLocList is empty
    if (selectedDeploymentLocList.length === 0) {
      return;
    }

    setLastUpdated("更新中...");

    let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/nfc-location-deployment";
    let data = {
      'nfcLocationID': selectedDeployment,
      'location': selectedDeploymentLocList,
      'organizationID': localStorage.getItem("organizationId")
    };
    
    fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(data => {
        // refresh deployment list
        RetrieveDeployList({setDeploymentList});

        // refresh last updated time
        setLastUpdated(new Date().toLocaleString());
      })
  }, [selectedDeploymentLocList]);

    
  return (
    <div>
      <label htmlFor="locationName" className="block text-sm font-medium leading-6 text-gray-900">
        選擇安裝
      </label>
      <div className="mt-2">
        {/* based on deploymentList, create a select tag */}
        <select
          id="locationName"
          name="locationName"
          className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={(event) => setSelectedDeployment(event.target.value)}
          value={selectedDeployment}
        >
          <option value="">選擇安裝</option>
          {deploymentList.map((deployment) => (
            <option key={deployment._id} value={deployment._id}>{deployment.name}</option>
          ))}
        </select>
      </div>
      <div
        className="my-2 text-sm text-gray-500"  
      >
        最後更新時間: {lastUpdated}
      </div>
      {/* show stats of how many out of total is installed */}
      <div
        className="my-2 text-sm text-gray-500"
      >
        已安裝 {selectedDeploymentLocList.filter((loc) => loc.deploymentStatus).length} / {selectedDeploymentLocList.length}
      </div>
      {/* display location table using DataSheetGrid */}
      {selectedDeploymentLocList.length > 0 && (
        <div
          className="mt-1 max-w-[80vw] overflow-x-auto"
        >
          <DataSheetGrid
            value={selectedDeploymentLocList}
            onChange={setSelectedDeploymentLocList}
            columns={columns}
            lockRows
            disableExpandSelection
            disableContextMenu
            rowHeight={35}
          />
        </div>
      )}
    </div>
  )
}


// NFCJobProgress page
export default function NFCJobProgress() {
    // set title tag to be NFC
    document.title = "NFC";

    // set up state for content
    const [content, setContent] = useState("N/A");
    const [id, setId] = useState("N/A");
    const [btnText, setBtnText] = useState("掃描 NFC");
    const [browserSupportNFC, setBrowserSupportNFC] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("處理中");
    const [modalDescription, setModalDescription] = useState("");
    const [modalStatus, setModalStatus] = useState("loading");
    const [modalBtnText, setModalBtnText] = useState("關閉");
    const [openSlideOver, setOpenSlideOver] = useState(false);
    const [selectedJobId, setSelectedJobId] = useState("");
    const [selectedStatus, setSelectedStatus] = useState("");
    const [selectedRuleId, setSelectedRuleId] = useState("");
    const [formTitle, setFormTitle] = useState("新增工作更改規則");
    const [editMode, setEditMode] = useState(false);

    const [showNotification, setShowNotification] = useState(false);
    const [showNFCInstallSlideOver, setShowNFCInstallSlideOver] = useState(false);

    const [selectedLocationName, setSelectedLocationName] = useState([]);

    // handle tab switch
    const [selectedTab, setSelectedTab] = useState([
      { name: '地點', current: true},
      { name: '工作單號', current: false}
    ]);


    // process job and progress job 
    function ProcessJob(url) {
        // confirm users for processing job
        if (!window.confirm("確定要處理此工作嗎？")) {
            return;
        }

        // disect url by job id where url format is /progress-job-by-rule/<job_id>
        let job_id = url.split("/").pop();

        // call job progress by url api /api/v1/jobs/progress-by-rules with a body of { "jobID": <job_id> }
        fetch(GlobalVars.BACKEND_DOMAIN + "/api/v1/jobs/progress-by-rules", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "jobID": job_id,
                "assignedTo": localStorage.getItem("userid")
            })
        })
        .then(response => response.json())
        .then(data => {
            // setContent(data.message);

            // set title to be 工作狀態已更新
            setModalTitle("工作狀態已更新");

            // set description to be Job ID and complete time 
            setModalDescription("工作編號: " + data.jobID + "\n完成時間: " + data.changedAt);

            // set modal status to be success
            setModalStatus("success");

            // set modal open
            setModalOpen(true);

        })
        .catch(error => {
            // setContent(error.message);

            // set modal open
            setModalOpen(true);

            // set title to be 工作狀態更新失敗
            setModalTitle("工作狀態更新失敗");

            // set description to be error message
            setModalDescription(error.message);

            // set modal status to be error
            setModalStatus("error");

        });

        // set modal status to be loading 
        setModalStatus("loading");

        // set title to be 處理中
        setModalTitle("處理中");

    }

    // check if NDENReader is supported
    useEffect(() => {
        if (!("NDEFReader" in window)) {
            setBrowserSupportNFC(false);
        }
    }, []);

    // scan NFC
    const [isScanning, setIsScanning] = useState(false);
    const scan = async () => {
        // prevent multiple scans
        if (isScanning) {
            return;
        }
        setIsScanning(true);

        setBtnText("掃描中...");
        try {
            if ("NDEFReader" in window) {
                console.log("Supported");
                const reader = new NDEFReader();
                await reader.scan();
                reader.onreading = event => {
                    setId(event.serialNumber);
                    const decoder = new TextDecoder();
                    for (const record of event.message.records) {
                        // console.log("Record type:  " + record.recordType);
                        // console.log("MIME type:    " + record.mediaType);
                        
                        let data = decoder.decode(record.data);
                        console.log("=== data ===\n" + data);
                        setContent(data);

                        // recognize if it's a url and process job
                        if (data.includes("https://")) {
                            ProcessJob(data);
                        }
                    }
                    setBtnText("掃描 NFC");

                    // unlock scan button
                    setIsScanning(false);
                };
            }
        } catch (error) {
            console.error("Error scanning NFC:", error);
            setContent("Error scanning NFC: " + error);
            setBtnText("Scan NFC");
        }
    };

    // upload job rule to backend
    const handleSubmit = (event) => {
        event.preventDefault();

        let submitData = {};
        if (selectedTab[0].current) {  // if selected tab is location
          // prompt for users confirmation
          console.log(selectedLocationName)
          if (!window.confirm("確定要新增此工作更改規則嗎？")) {
            return;
          }

          // extract value from selectedLocationName and create a list 
          let locationList = [];
          selectedLocationName.forEach((location) => {
            locationList.push(location.value);
          });

          submitData = {
            "locationName": locationList,
            "status": "active",
            "organizationID": localStorage.getItem("organizationId"),
            "type": "location"
          };

          console.log(submitData)

          // if edit mode is true, change submitData to include ruleID
          if (editMode) {
            submitData.ruleID = selectedRuleId;
          }

        } else if (selectedTab[1].current) { // if selected tab is job id
          submitData = {
            "jobID": selectedJobId,
            "targetProgress": selectedStatus,
            "status": "active",
            "organizationID": localStorage.getItem("organizationId"),
            "type": "jobID"
          };

          // if edit mode is true, change submitData to include ruleID
          if (editMode) {
            submitData.ruleID = selectedRuleId;
          }
        }

        // fetch request to /api/v1/job-rules to create a new job rule
        fetch(GlobalVars.BACKEND_DOMAIN + "/api/v1/job-rules", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(submitData)
        })
        .then(response => response.json())
        .then(data => {
            // close slide over
            setModalOpen(false);

            console.log(data);
            alert("新增成功");

            // redirect to nfc job progress page
            window.location.href = "/nfc-job-progress";
            
        })
        .catch(error => {
            console.log(error);
            alert("新增失敗");
        });

        
    };

    return (
        <>
            <ApplicationShell>
                <div className="md:flex md:items-center md:justify-between">
                    <div className="min-w-0 flex-1">
                        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                            NFC 工作狀態更改規則
                        </h2>
                    </div>
                    <div className="mt-4 flex md:ml-4 md:mt-0">
                        <button
                          type="button"
                          className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          onClick={() => {
                            setShowNFCInstallSlideOver(true);
                          }}
                        >
                        安裝NFC
                        </button>
                        <button
                        type="button"
                        className="ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        onClick={() => {
                          setOpenSlideOver(true);
                          // set form title
                          setFormTitle("新增工作更改規則");

                          // set form data
                          setSelectedJobId("");
                          setSelectedStatus("新工作");
                          setSelectedRuleId("");

                          // set edit mode to false
                          setEditMode(false);
                        }}
                        >
                        新增規則
                        </button>
                        <button
                        type="button"
                        className="ml-3 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={scan}
                        disabled={isScanning}
                        >
                        {btnText}
                        </button>
                        
                    </div>
                </div>
                <JobRuleList 
                  openSlide={setOpenSlideOver} 
                  setSelectedJobId={setSelectedJobId} 
                  setSelectedStatus={setSelectedStatus} 
                  setFormTitle={setFormTitle} 
                  setEditMode={setEditMode} 
                  setSelectedRuleId={setSelectedRuleId} 
                  setShowNotification={setShowNotification}
                />
            </ApplicationShell>
            <SlideOvers 
            open={openSlideOver} 
            setOpen={setOpenSlideOver} 
            title={formTitle} 
            submitAction={handleSubmit}
            content={<NewJobRuleForm 
                      editMode={editMode}
                      selectedJobId={selectedJobId} 
                      setSelectedJobId={setSelectedJobId} 
                      selectedStatus={selectedStatus} 
                      setSelectedStatus={setSelectedStatus} 
                      setFormTitle={setFormTitle} 
                      selectedRuleId={selectedRuleId}
                      setSelectedRuleId={setSelectedRuleId}
                      selectedLocationName={selectedLocationName}
                      setSelectedLocationName={setSelectedLocationName}
                      selectedTab={selectedTab}
                      setSelectedTab={setSelectedTab}
                      />}  
            />
            <StatusModals setOpen={setModalOpen} open={modalOpen} status={modalStatus} title={modalTitle} description={modalDescription} btnText={modalBtnText} />
            <FloatingMessage show={showNotification} setShow={setShowNotification} />
            <NFCInstallationSlideOver open={showNFCInstallSlideOver} setOpen={setShowNFCInstallSlideOver} />
        </>
    )
}


