import ApplicationShell from "../../Components/ApplicationShell";
import { useState, useEffect } from "react";
import GlobalVars from "../../Config";
import {
    BarChart,
    LineChart,
    Line,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
  } from 'recharts';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'

const statuses = GlobalVars.statuses;

export default function Dashboard() {
    // set the title of the page
    document.title = "儀表板";

    return (
        <>
            <ApplicationShell>
                <PageHeadings />
                <DataCards />
                <EstimatedTodayLocation />
                <hr className="my-5"></hr>
                <MonthlyStatistics />
            </ApplicationShell>
        </>
    )
}

function PageHeadings() {
    return (
        <div className="md:flex md:items-center md:justify-between mb-5">
            <div className="min-w-0 flex-1">
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                儀表板
                </h2>
            </div>
            {/* <div className="mt-4 flex md:ml-4 md:mt-0">
                <button
                type="button"
                className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                Edit
                </button>
                <button
                type="button"
                className="ml-3 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                Publish
                </button>
            </div> */}
        </div>
    )
}

// monthly statistics using rechart library for locations, jobs, and users
function MonthlyStatistics() {
    const [locStats, setLocStats] = useState([]);
    const [jobStats, setJobStats] = useState([]);
    const [userStats, setUserStats] = useState([]);

    const [targetMonthYear, setTargetMonthYear] = useState(new Date().toISOString().slice(0, 7)); // format: YYYY
    const [targetMonth, setTargetMonth] = useState(new Date().toISOString().slice(5, 7)); // format: MM
    const [targetYear, setTargetYear] = useState(new Date().toISOString().slice(0, 4)); // format: YYYY

    useEffect(() => {
        console.log(targetMonth)
        console.log(targetYear)
        // set the target month and year
        setTargetMonthYear(targetYear + "-" + targetMonth);
    }, [targetYear, targetMonth]);

    // year list options that are numerical string for the past 10 years
    const [yearList, setYearList] = useState([]);
    useEffect(() => {
        const years = [];
        let currentYear = new Date().toISOString().slice(0, 4);
        for (let i = 0; i < 10; i++) {
            years.push(currentYear);
            currentYear = parseInt(currentYear) - 1;
        }
        setYearList(years);
    }, []);

    return (
      <div>
        <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-5 bg-white sticky top-[200px] py-2">
            <div className="min-w-0 flex-1">
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                每月統計
                </h2>
            </div>
            <div className="mt-4 flex md:ml-4 md:mt-0 w-full sm:w-[30%] items-center">
              <h3 className="whitespace-nowrap">選擇月份</h3>
              <select
                id="month"
                name="month"
                className="ml-2 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                defaultValue={targetMonth}
                onChange={(e) => setTargetMonth(e.target.value)}
              >
                <option value="01">01</option>
                <option value="02">02</option>
                <option value="03">03</option>
                <option value="04">04</option>
                <option value="05">05</option>
                <option value="06">06</option>
                <option value="07">07</option>
                <option value="08">08</option>
                <option value="09">09</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </select>
              <select 
                id="year"
                name="year"
                className="ml-3 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                defaultValue={targetYear}
                onChange={(e) => setTargetYear(e.target.value)}
              >
                {yearList.map((year) => (
                  <option value={year}>{year}</option>
                ))}
              </select>
            </div>
        </div>
        <div
          className=""
        >
          <div className="grid grid-cols-1 gap-8 md:grid-cols-2 gap-y-12">
              <MonthlyStatisticsLocations targetMonthYear={targetMonthYear}/>
              <MonthlyStatisticsJobs targetMonthYear={targetMonthYear}/>
              <MonthlyStatisticsUsers targetMonthYear={targetMonthYear}/>
              <DailyJobCountPast7Days />
              <DailyJobCountPast14Days />
              <DailyJobCountPast30Days />
          </div>
        </div>
      </div>
    )
}

// fetch daily statistic of jobs to display number of jobs per day 
function DailyJobCountPast7Days() {
  const [dailyJobStats, setDailyJobStats] = useState([]);

  useEffect(() => {
    if (dailyJobStats.length > 0) {
      return;
    }

    fetch(`${GlobalVars.ANALYTIC_DOMAIN}/api/v1/dashboards/daily-statistics-7-days?type=job&organizationID=${sessionStorage.getItem("organizationId")}`, {
      method: 'GET',
      headers: {
        'Authorization': sessionStorage.getItem("idToken")
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        let data = response.json();
        return data;
      })
      .then((rawData) => {
        let data = rawData['data'];
        // group by createdAt key 
        var key;
        // let groups = data.reduce((acc, item) => {
        //   key = item.createdAt;
        //   if (!acc[key]) {
        //     acc[key] = {
        //       name: key,
        //       count: 0
        //     };
        //   }
        //   acc[key].count += 1;
        //   return acc;
        // });

        // group by createdAt key
        let groups = {};
        for (var i = 0; i < data.length; i++) {
          key = data[i].createdAt;
          if (!groups.hasOwnProperty(key)) {
            groups[key] = {
              name: key,
              count: 0
            };
          }
          groups[key].count += 1;
          
        }

        // convert to list
        groups = Object.values(groups);
        // groups = groups.slice(1)
        
        // given date of the week (e.g. Mon, Tue, Wed, ...) sort the data by the day of the week in ascending order
        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        groups.sort((a, b) => days.indexOf(a.name) - days.indexOf(b.name));

        // set the state
        setDailyJobStats(...dailyJobStats, groups);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <ResponsiveContainer width="100%" height={400}>
      <h1>今個星期每日工作數量</h1>
      <LineChart
        data={dailyJobStats}
        margin={{
          top: 20, right: 30, left: 20, bottom: 5,
        }}
        padding={{
          top: 20, right: 30, left: 20, bottom: 40,
        }}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis
          dataKey="name"
          angle={300}
          textAnchor="end"
          height={60}
          className={`text-[10px] ${window.innerWidth <= 768 ? 'hidden' : ''}`}
        />
        <YAxis label={{ value: '次數', angle: -90, position: 'insideLeft' }}  />
        <Tooltip content={<CustomTooltip />} />
        {/* <Legend /> */}
        <Line dataKey="count" stackId="a" fill="#8884d8" />
      </LineChart>
    </ResponsiveContainer>

  )
}

// fetch daily statistic of jobs to display number of jobs per day 
function DailyJobCountPast14Days() {
  const [dailyJobStats, setDailyJobStats] = useState([]);

  useEffect(() => {
    fetch(`${GlobalVars.ANALYTIC_DOMAIN}/api/v1/dashboards/daily-statistics-14-days?type=job&organizationID=${sessionStorage.getItem("organizationId")}`, {
      method: 'GET',
      headers: {
        'Authorization': sessionStorage.getItem("idToken")
      }
    })
      .then((response) => response.json())
      .then((rawData) => {
        let data = rawData['data'];
        // group by createdAt key 
        // let groups = data.reduce((acc, item) => {
        //   const key = item.createdAt; // "YYYY-MM-DD"
        //   if (!acc[key]) {
        //     acc[key] = {
        //       name: key,
        //       count: 0
        //     };
        //   }
        //   acc[key].count += 1;
        //   return acc;
        // });
        let groups = {}
        let key;
        for (var i = 0; i < data.length; i++) {
          key = data[i].createdAt;
          if (!groups.hasOwnProperty(key)) {
            groups[key] = {
              name: key,
              count: 0
            };
          }
          groups[key].count += 1;
          
        }

        // convert to list
        groups = Object.values(groups);
        // groups = groups.slice(1)
      
        // flip the order the list
        groups = groups.reverse();

        // set the state
        setDailyJobStats(groups)
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <ResponsiveContainer width="100%" height={400}>
      <h1>過往14天工作數量</h1>
      <LineChart
        data={dailyJobStats}
        margin={{
          top: 20, right: 30, left: 20, bottom: 5,
        }}
        padding={{
          top: 20, right: 30, left: 20, bottom: 40,
        }}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis
          dataKey="name"
          angle={300}
          textAnchor="end"
          height={60}
          className={`text-[10px] ${window.innerWidth <= 768 ? 'hidden' : ''}`}
        />
        <YAxis label={{ value: '次數', angle: -90, position: 'insideLeft' }}  />
        <Tooltip content={<CustomTooltip />} />
        {/* <Legend /> */}
        <Line dataKey="count" stackId="a" fill="#8884d8" />
      </LineChart>
    </ResponsiveContainer>

  )
}

// fetch daily statistic of jobs to display number of jobs per day 
function DailyJobCountPast30Days() {
  const [dailyJobStats, setDailyJobStats] = useState([]);

  useEffect(() => {
    fetch(`${GlobalVars.ANALYTIC_DOMAIN}/api/v1/dashboards/daily-statistics-30-days?type=job&organizationID=${sessionStorage.getItem("organizationId")}`, {
      method: 'GET',
      headers: {
        'Authorization': sessionStorage.getItem("idToken")
      }
    })
      .then((response) => response.json())
      .then((rawData) => {
        let data = rawData['data'];
        // group by createdAt key 
        // let groups = data.reduce((acc, item) => {
        //   const key = item.createdAt; // "YYYY-MM-DD"
        //   if (!acc[key]) {
        //     acc[key] = {
        //       name: key,
        //       count: 0
        //     };
        //   }
        //   acc[key].count += 1;
        //   return acc;
        // });
        let groups = {}
        let key;
        for (var i = 0; i < data.length; i++) {
          key = data[i].createdAt;
          if (!groups.hasOwnProperty(key)) {
            groups[key] = {
              name: key,
              count: 0
            };
          }
          groups[key].count += 1;   
        }

        // convert to list
        groups = Object.values(groups);
        // groups = groups.slice(1)
      
        // flip the order the list
        groups = groups.reverse();

        // set the state
        setDailyJobStats(groups)
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <ResponsiveContainer width="100%" height={400}>
      <h1>過往30天工作數量</h1>
      <LineChart
        data={dailyJobStats}
        margin={{
          top: 20, right: 30, left: 20, bottom: 5,
        }}
        padding={{
          top: 20, right: 30, left: 20, bottom: 40,
        }}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis
          dataKey="name"
          angle={300}
          textAnchor="end"
          height={60}
          className={`text-[10px] ${window.innerWidth <= 768 ? 'hidden' : ''}`}
        />
        <YAxis label={{ value: '次數', angle: -90, position: 'insideLeft' }}  />
        <Tooltip content={<CustomTooltip />} />
        {/* <Legend /> */}
        <Line dataKey="count" stackId="a" fill="#8884d8" />
      </LineChart>
    </ResponsiveContainer>

  )
}

// fetch monthly statistics for locations
function MonthlyStatisticsLocations({targetMonthYear}) {
  const [rawLocStats, setRawLocStats] = useState([]);
  const [locStats, setLocStats] = useState([]);

  useEffect(() => {
    // fetch for monthly statistics for locations
    fetch(`${GlobalVars.ANALYTIC_DOMAIN}/api/v1/dashboards/monthly-statistics?type=location&organizationID=${sessionStorage.getItem("organizationId")}`, {
      method: 'GET',
      headers: {
        'Authorization': sessionStorage.getItem("idToken")
      }
    })
    .then((response) => response.json())
    .then((rawData) => {
        let data = rawData['data'];

        // aggregate the data (format: [{createdAt: <date YYYY-MM-DD HH:MM:SS>, locationName: <name>}, ...]) so the format becomes [{'month': <month>, 'location': {<location>: <count>, ...}}, ...]
        let sortedData = transformLocData(data);
        setRawLocStats(sortedData);
        
        // set the state
        setLocStats(processSortData(sortedData, targetMonthYear));
    })
    .catch((error) => {
        console.log(error);
    });
  }, [])

  // update based on target month and year
  useEffect(() => {
    setLocStats(processSortData(rawLocStats, targetMonthYear));
  }, [targetMonthYear]);

  return (
    <ResponsiveContainer width="100%" height={460}>
      <div>
        <h1>每月各工作地點排行</h1>

      </div>
      
      <BarChart
          data={locStats}
          margin={{
          top: 20, right: 30, left: 20, bottom: 60,
          }}
          padding={{
              top: 20, right: 30, left: 20, bottom: 40,
          }}
      >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
              dataKey="name"
              angle={300}
              textAnchor="end"
              height={60}
              className={`text-[10px] ${window.innerWidth <= 768 ? 'hidden' : ''}`}
          />
          <YAxis label={{ value: '次數', angle: -90, position: 'insideLeft' }}  />
          <Tooltip content={<CustomTooltip />} />
          {/* <Legend /> */}
          <Bar dataKey="count" stackId="a" fill="#8884d8" />
      </BarChart>
  </ResponsiveContainer>
  )
}

// fetch for monthly statistics for jobs
function MonthlyStatisticsJobs({targetMonthYear}) {
    const [rawJobStats, setRawJobStats] = useState([]);
    const [jobStats, setJobStats] = useState([]);
    
    useEffect(() => {
      // fetch for monthly statistics for job types
      fetch(`${GlobalVars.ANALYTIC_DOMAIN}/api/v1/dashboards/monthly-statistics?type=jobType&organizationID=${sessionStorage.getItem("organizationId")}`, {
        method: 'GET',
        headers: {
          'Authorization': sessionStorage.getItem("idToken")
        }
      })
      .then((response) => response.json())
      .then((rawData) => {
          let data = rawData['data'];

          // transform the data
          let sortedData = transformLocData(data);
          setRawJobStats(sortedData);

          // set the state
          setJobStats(processSortData(sortedData, targetMonthYear));
      })
      .catch((error) => {
          console.log(error);
      });
    }, []);

    // update based on target month and year
    useEffect(() => {
      setJobStats(processSortData(rawJobStats, targetMonthYear));
    }, [targetMonthYear]);

    return (
      <ResponsiveContainer width="100%" height={400}>
        <h1>每月各工作類別排行</h1>
            <BarChart
                data={jobStats}
                margin={{
                top: 20, right: 30, left: 20, bottom: 5,
                }}
                padding={{
                    top: 20, right: 30, left: 20, bottom: 40,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey="name"
                    angle={300}
                    textAnchor="end"
                    height={60}
                    className={`text-[10px] ${window.innerWidth <= 768 ? 'hidden' : ''}`}
                />
                <YAxis label={{ value: '次數', angle: -90, position: 'insideLeft' }}  />
                <Tooltip content={<CustomTooltip />} />
                {/* <Legend /> */}
                <Bar dataKey="count" stackId="a" fill="#8884d8" />
            </BarChart>
            
      </ResponsiveContainer>
    )
}

// fetch for monthly statistics for users
function MonthlyStatisticsUsers({targetMonthYear}) {
    const [rawUserStats, setRawUserStats] = useState([]);
    const [userStats, setUserStats] = useState([]);

    useEffect(() => { 
      // fetch for monthly statistics for users
      fetch(`${GlobalVars.ANALYTIC_DOMAIN}/api/v1/dashboards/monthly-statistics?type=staff&organizationID=${sessionStorage.getItem("organizationId")}`, {
        method: 'GET',
        headers: {
          'Authorization': sessionStorage.getItem("idToken")
        }
      })
          .then((response) => response.json())
          .then((rawData) => {
              let data = rawData['data'];

              // transform the data
              let sortedData = transformLocData(data);
              setRawUserStats(sortedData);

              // set the state
              setUserStats(processSortData(sortedData, targetMonthYear));
          })
          .catch((error) => {
              console.log(error);
          });


    }, []);

    // update based on target month and year
    useEffect(() => {
      setUserStats(processSortData(rawUserStats, targetMonthYear));
    }, [targetMonthYear]);

    return (
      <ResponsiveContainer width="100%" height={450}>
        <h1>每月各施工人員排行</h1>
          <BarChart
              data={userStats}
              margin={{
              top: 20, right: 30, left: 20, bottom: 60,
              }}
              padding={{
                  top: 20, right: 30, left: 20, bottom: 40,
              }}
          >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                  dataKey="name"
                  angle={300}
                  textAnchor="end"
                  height={60}
                  className={`text-[10px] ${window.innerWidth <= 768 ? 'hidden' : ''}`}
              />
              <YAxis label={{ value: '次數', angle: -90, position: 'insideLeft' }}  />
              <Tooltip content={<CustomTooltip />} />
              {/* <Legend /> */}
              <Bar dataKey="count" stackId="a" fill="#8884d8" />
          </BarChart>
      </ResponsiveContainer>
    )
  }





  // aggregate the data (format: [{createdAt: <date YYYY-MM-DD HH:MM:SS>, locationName: <name>}, ...]) so the format becomes [{'month': <month>, 'location': {<location>: <count>, ...}}, ...]
function transformLocData(data) {
    const result = [];

    // Group by locationName and month
    const groups = data.reduce((acc, item) => {
        // Extract the year and month from createdAt
        const month = item.createdAt.slice(0, 7); // "YYYY-MM"
        const key = `${item.name}-${month}`;

        // Initialize if not present
        if (!acc[key]) {
        acc[key] = {
            month,
            name: item.name,
            count: 0
        };
        }

        // Increment the count for the group
        acc[key].count += 1;

        return acc;
    }, {});

    // Convert the grouped object into an array
    for (const group of Object.values(groups)) {
        result.push(group);
    }

    return result;
}
  
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const staffLocStyle = {
  "office": "bg-green-500",
  "unknown": "bg-gray-300",
  "other": "bg-indigo-500",
}

function DataCards() {
    const [staffList, setStaffList] = useState([]);

      const [filteredStaffList, setFilteredStaffList] = useState([...staffList]);
      const [searchTerm, setSearchTerm] = useState("");


      // fetch for total staff list and get today's location 
      useEffect(() => {
        fetch(`${GlobalVars.ANALYTIC_DOMAIN}/api/v1/dashboards/location-information?organizationID=${sessionStorage.getItem("organizationId")}`, {
          method: 'GET',
          headers: {
            'Authorization': sessionStorage.getItem("idToken")
          }
        })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json()
        })
        .then((rawData) => {
            let data = rawData['data'];

            for (let i = 0; i < data.length; i++) {
              // adding style based on location
              if (data[i]['location'] === "") {
                data[i]['style'] = staffLocStyle['unknown'];
              } else if (data[i]['location'] === "回公司") {
                data[i]['style'] = staffLocStyle['office'];
              } else {
                data[i]['style'] = staffLocStyle['other'];
              }

              // add url 
              data[i]['href'] = "/jobs/" + data[i]["jobID"];
            }

            // set the state
            setStaffList(data);
            setFilteredStaffList(data.slice(0, 10));  // limit to 10

        });

      }, []);

      useEffect(() => {
          let filtered = searchTerm
              ? staffList.filter(staff => staff.name.includes(searchTerm)).slice(0, 10)
              : staffList.slice(0, 10);
          setFilteredStaffList(filtered);
      }, [staffList, searchTerm]);

    return (
      <div className="mb-6">
        <div
          className="flex sm:flex-row sm:items-center sm:justify-between flex-col"
        >
          <div>
            <h1 className="text-lg">同事現時位置</h1>
            <h2 className="text-sm font-medium text-gray-500">顯示最近工作位置</h2>
          </div>
          {/* search bar */}
          <div className="mt-4 flex md:ml-4 md:mt-0 sm:w-[30%] items-center w-full">
            <input
              type="text"
              name="search"
              id="search"
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="搜尋同事"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <ul role="list" className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
          {filteredStaffList.map((staff) => (
            <li className="col-span-1 flex rounded-md shadow-sm">
              <div
                className={classNames(
                  `${(staff.latestStatus?.status && staff.latestStatus?.['status'] != "") ? statuses[staff.latestStatus['status']] : "bg-gray-200 text-gray-400"} flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium`
                )}
              >
                {staff.latestStatus && staff.latestStatus.status != "" ? staff.latestStatus.status : '未有工作'}
              </div>
              <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                <div className="flex-1 truncate px-4 py-2 text-sm">
                  <a href={staff.href} className="font-medium text-gray-900 hover:text-gray-600 cursor-pointer">
                    {staff.name}
                  </a>
                  <p className="text-gray-500">{staff.location}</p>
                </div>
                <div className="flex-shrink-0 pr-2">
                  <button
                    type="button"
                    className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <span className="sr-only">Open options</span>
                    <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    )
}


function EstimatedTodayLocation() {
  const [estimatedStaffLoc, setEstimatedStaffLoc] = useState([]);

  const [filteredStaffLoc, setFilteredStaffLoc] = useState([...estimatedStaffLoc]);
  const [searchTerm, setSearchTerm] = useState("");

  // retrieve data from API (/api/v1/dashboards/todays-locations) to get the estimated location of the staff
  useEffect(() => {
    fetch(`${GlobalVars.ANALYTIC_DOMAIN}/api/v1/dashboards/todays-locations?organizationID=${sessionStorage.getItem("organizationId")}`, {
      method: 'GET',
      headers: {
        'Authorization': sessionStorage.getItem("idToken")
      }
    })
      .then((response) => response.json())
      .then((rawData) => {
        let data = rawData['data'];


        // adding style based on location
        for (let i = 0; i < data.length; i++) {
          if (data[i]['location'] === "") {
            data[i]['style'] = staffLocStyle['unknown'];
          } else if (data[i]['location'] === "回公司") {
            data[i]['style'] = staffLocStyle['office'];
          } else {
            data[i]['style'] = staffLocStyle['other'];
          }
        }

        setEstimatedStaffLoc(data);

        // set the state
        setFilteredStaffLoc(data.slice(0, 10));  // limit to 10

      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    let filtered = searchTerm
        ? estimatedStaffLoc.filter(staff => staff.name.includes(searchTerm)).slice(0, 10)
        : estimatedStaffLoc.slice(0, 10);
    setFilteredStaffLoc(filtered);
  }, [estimatedStaffLoc, searchTerm]);

  return (
    <div className="mb-6">
      <div
        className="flex sm:flex-row sm:items-center sm:justify-between flex-col"
      >
        <div>
          <h1 className="text-lg">同事今天位置</h1>
          <h2 className="text-sm font-medium text-gray-500">會包含預計工作日期為今天的工作</h2>
        </div>
        <div className="mt-4 flex md:ml-4 md:mt-0 sm:w-[30%] items-center">
          <input
            type="text"
            name="search"
            id="search"
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="搜尋同事"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>
      <ul role="list" className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
        {filteredStaffLoc.map((staff) => (
          <li className="col-span-1 flex rounded-md shadow-sm">
            <div
              className={classNames(
                `${staff['style']} flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white`
              )}
            >
              {staff.latestStatus && staff.latestStatus.status != "" ? staff.latestStatus.status : ''}
            </div>
            <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
              <div className="flex-1 truncate px-4 py-2 text-sm">
                <a href={staff.href} className="font-medium text-gray-900 hover:text-gray-600">
                  {staff.name}
                </a>
                <p className="text-gray-500">{staff.location}</p>
              </div>
              <div className="flex-shrink-0 pr-2">
                <button
                  type="button"
                  className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span className="sr-only">Open options</span>
                  <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

// process sort data 
function processSortData(rawLocStats, targetMonthYear, displayPoints=15) {
    // handle the change of target month and year
    let sortedData = rawLocStats.filter((item) => item.month === targetMonthYear);

    // check locationName character length, if more than 10, then shorten it
    for (let i = 0; i < sortedData.length; i++) {
      // skip if it's undefined
      if (sortedData[i].name === undefined) {
          continue;
      }

      let lengthLimit = 20;
      if (sortedData[i].name.length > lengthLimit) {
          sortedData[i].name = sortedData[i].name.slice(0, lengthLimit) + "...";
      }
    }
    
    // sort by count and limit to specified number of display points
    sortedData.sort((a, b) => b.count - a.count);
    sortedData = sortedData.slice(0, displayPoints);

    return sortedData;
}

function CustomTooltip({active, payload, label}) {
  if (active && payload && payload.length) {
    return (
      <div className="bg-gray-700 rounded p-2 shadow-inner text-white w-[140px] text-sm">
        <p className="text-gray-300 text-xs">{`${label}`}</p>
        <p className="text-white text-lg">{payload[0].value}</p>
        {/* <p className="intro">{getIntroOfPage(label)}</p> */}
        {/* <p className="desc">Anything you want can be displayed here.</p> */}
      </div>
    )
  }

  return null;
}


