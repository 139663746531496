// globals.js
const GlobalVars = {
    BACKEND_DOMAIN: "https://job-management-service-dot-otter-and-seal.df.r.appspot.com",
    // BACKEND_DOMAIN: "http://127.0.0.1:5000",
    ANALYTIC_DOMAIN: "https://job-management-analytics-dot-otter-and-seal.df.r.appspot.com",
    // ANALYTIC_DOMAIN: "http://127.0.0.1:5001",
    ASSISTANT_DOMAIN: "https://ai-assistant-service-dot-otter-and-seal.df.r.appspot.com",
    // ASSISTANT_DOMAIN: "http://127.0.0.1:5002",
    firebase: {
        apiKey: "AIzaSyCZ6HpNl7kZwevmiwnEcgf4RrnHO51LDtA",
        authDomain: "otter-and-seal.firebaseapp.com",
        projectId: "otter-and-seal",
        storageBucket: "otter-and-seal.appspot.com",
        messagingSenderId: "433587317721",
        appId: "1:433587317721:web:dd41293420b7d7f19047c8"
    },
    firebase_publick_fcm_key: "BFh0W_PFnkeAQmnBukFQ4vv-fG-Tx3fIHXQhpgL2pAbUhjfqLIHkMARWviL74iQ5gtKJG_hIrGCZOBt1ZP9Yi3s",
    statuses: {
        "新工作": 'text-white-600 bg-white-50 ring-white-500/10',
        "待分配": "text-white-600 bg-white-50 ring-white-500/10",
        "待確認": "text-pink-600 bg-pink-50 ring-pink-500/10",
        "未開始": "text-gray-600 bg-gray-50 ring-gray-500/10",
        "進行中": "text-green-600 bg-green-50 ring-green-500/10",
        "中斷": "text-red-600 bg-red-50 ring-red-500/10",
        "待續": "text-yellow-600 bg-yellow-50 ring-yellow-500/10",
        "待檢查": "text-blue-600 bg-blue-50 ring-blue-500/10",
        "待批准": "text-blue-600 bg-blue-50 ring-blue-500/10",
        "完成": "text-orange-600 bg-orange-50 ring-orange-500/10",
        "拒絕": "text-red-600 bg-red-50 ring-red-500/10",
        "取消": "text-red-600 bg-red-50 ring-red-500/10",
        "延期": "text-yellow-600 bg-yellow-50 ring-yellow-500/10",
        "回公司": "text-yellow-600 bg-yellow-50 ring-yellow-500/10",
        "緊急": "text-red-600 bg-red-50 ring-red-500/10",
        "維修中": "text-yellow-600 bg-yellow-50 ring-yellow-500/10",
        "需要額外部件": "",
        "需要專家幫助": "",
        "通知": "text-lime-600 bg-lime-50 ring-lime-500/10",
      }
};

export default GlobalVars;
