import ApplicationShell from "../../Components/ApplicationShell";
import { useEffect, useState } from "react";

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getMessaging, getToken } from 'firebase/messaging'
import GlobalVars from "../../Config";

export default function Settings() {
    // set title to 設定
    document.title = "設定";

    const [notificationPermission, setNotificationPermission] = useState("");

    const EnableNotification = () => {
        console.log('Manually request permission to get notification');
        Notification.requestPermission().then((permission) => {
            setNotificationPermission(permission);
            
            if (permission === 'granted') {
                console.log('Notification permission granted.');
                // retrieve FCM token
                const messaging = getMessaging();
                getToken(messaging, { vapidKey: GlobalVars.firebase_publick_fcm_key }).then((currentToken) => {
                    if (currentToken) {
                        
                        // create POST request to verify FCM token from /api/v1/users/fcm
                        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/users/fcm";
                        let body = {
                            "fcmToken": currentToken,
                            "userID": localStorage.getItem("userid"),
                            "device": navigator.userAgent
                        };
                        let headers = {
                          "Content-Type": "application/json"
                        };

                        fetch(url, {
                            method: "POST",
                            body: JSON.stringify(body),
                            headers: headers
                        }).then(response => {
                            if (response.status === 200) {
                                // store FCM token in sessionStorage
                                sessionStorage.setItem("fcmTokenStored", true);

                                setNotificationPermission("Notification enabled");
                            } else {
                                console.log("Error: " + response.status);

                                setNotificationPermission("Error: " + response.status);
                            }
                        });
                        
                    } else {
                        // Show permission request UI
                        console.log('No registration token available. Request permission to generate one.');
                    }
                });
            }
        });
    };


    return (
        <>
            <ApplicationShell>
                <div className="bg-white shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:p-6">
                        <h3 className="text-base font-semibold leading-6 text-gray-900">管理通知</h3>
                        <div className="mt-2 max-w-xl text-sm text-gray-500">
                        </div>
                        <div className="mt-5 flex items-center">
                            <button
                                type="button"
                                className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                onClick={EnableNotification}
                            >
                                開通通知功能
                            </button>
                            <p
                                className="mt-2 text-sm text-gray-500"
                                id="enable-notification-description"
                            >
                                {notificationPermission}
                            </p>
                        </div>
                        <div
                            className="mt-5 border-t border-gray-200"
                            aria-labelledby="notification-preference"
                        >
                            <NotificationPreference />
                        </div>
                    </div>
                </div>
                <div
                    className="bg-white shadow sm:rounded-lg mt-2"
                >
                    <AdjustUserStyleSetting />
                </div>
            </ApplicationShell>
        </>
    )
}

// list of checkbox for notification preference
function NotificationPreference() {
    const [notificationPreference, setNotificationPreference] = useState({
    });
    const [isInitialLoadComplete, setIsInitialLoadComplete] = useState(false);

    // retrieve notification preference from backend
    useEffect(() => {
        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/notification/notification-preference?userID=" + localStorage.getItem("userid");
        fetch(url).then(response => {
            if (response.status === 200) {
                response.json().then(data => {
                    setNotificationPreference(data['preferences']);
                });
            } else {
                console.log("Error: " + response.status);
            }
        })
        .finally(() => {
            setIsInitialLoadComplete(true);
        });
    }, []);

    // update when users change the preference
    useEffect(() => {
        // return if initial load is not complete
        if (!isInitialLoadComplete) {
            return;
        }

        // skip if notificationPreference is empty
        if (Object.keys(notificationPreference).length === 0) {
            return;
        } else {
            console.log(notificationPreference);
        }

        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/notification/notification-preference";
        let body = {
            "userID": localStorage.getItem("userid"),
            "preferences": notificationPreference
        };  
        let headers = {
            "Content-Type": "application/json"
        };

        fetch(url, {
            method: "PUT",
            body: JSON.stringify(body),
            headers: headers
        }).then(response => {
            if (response.status === 200) {
                console.log("Notification preference updated");
            } else {
                console.log("Error: " + response.status);
            }
        });
    }, [notificationPreference]);

    return(
        <div
            className="py-3"
        >
            <h3
                className="text-base font-semibold leading-6 text-gray-900"
            >
                通知偏好
            </h3>
            <div
                className="mt-2 max-w-xl text-sm text-gray-500"
            >
                選擇您希望收到的通知
            </div>
            {/* list out all available preference */}
            <div
                className="mt-5 space-y-4"
            >
                <div
                    className="flex items-center"
                >
                    <input
                        id="daily-summary"
                        name="daily-summary"
                        type="checkbox"
                        className="h-4 w-4 text-indigo-600 focus-visible:ring-indigo-500 border-gray-300 rounded"
                        checked={notificationPreference.dailySummary}
                        onChange={(e) => {
                            setNotificationPreference({
                                ...notificationPreference,
                                dailySummary: e.target.checked
                            });
                        }}
                    />
                    <label
                        htmlFor="daily-summary"
                        className="ml-3 block text-sm font-medium text-gray-700"
                    >
                        每日摘要 
                        <span
                            className="text-gray-500"
                        >(每天晚上6時發送當天還沒有完成的工作)</span>
                    </label>
                </div>
            </div>
        </div>

    )
}

// adjust users style setting
function AdjustUserStyleSetting() {
    const [userStyle, setUserStyle] = useState({
        zoomLevel: 100
    });

    // retrieve user style setting from localStorage
    useEffect(() => {
        let userZoomLevel = parseInt(localStorage.getItem("userSetZoomLevel").replace("%", ""));
        if (userZoomLevel) {
            setUserStyle(prevState => ({ ...prevState, zoomLevel: userZoomLevel }));
        }
    }, []);


    // handle users update style change
    const handleUserStyleChange = (e) => {
        // get zoom level
        let zoomLevel = document.getElementById("zoom-level").value;

        // update user style setting in localStorage
        localStorage.setItem("userSetZoomLevel", zoomLevel + "%");


        alert("使用者設定已更新, 會為您重新載入頁面");
        window.location.reload();
    }

    return (
        <div
            className="px-4 py-5 sm:p-6"
        >   
            <div
                className="flex justify-between"
            >
                <h3 className="text-base font-semibold leading-6 text-gray-900">調整使用者設定</h3>
                <button
                    type="button"
                    className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                    onClick={handleUserStyleChange}
                >
                    儲存
                </button>
            </div>
            <br></br>
            <div>
                <label htmlFor="zoom-level" className="block text-sm font-medium leading-6 text-gray-900">
                    縮放比例
                </label>
                <p
                    className="mt-1 text-sm text-gray-500"
                >
                    100% 為原始大小. 請輸入 0-200 之間的數字. 大於 100% 為放大, 小於 100% 為縮小
                </p>
                <div className="relative mt-2 rounded-md shadow-sm">
                    <input
                    id="zoom-level"
                    name="zoom-level"
                    type="number"
                    placeholder="0"
                    className="block w-full rounded-md border-0 py-1.5 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={userStyle.zoomLevel}
                    onChange={(e) => {
                        setUserStyle({
                            ...userStyle,
                            zoomLevel: e.target.value
                        });
                    }}
                    />
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <span id="price-currency" className="text-gray-500 sm:text-sm">
                        %
                    </span>
                    </div>
                </div>
            </div>
        </div>
    )
}
