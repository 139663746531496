import { useEffect, useState, useRef } from "react"
import {
    PaperAirplaneIcon,
    XMarkIcon
} from '@heroicons/react/24/outline'
import GlobalVars from "../../Config"
// This is a floating widget similar to github copilot in Visual Studio that provides auto-completion suggestions or modify existing text based on natural language input 
export default function AIFloatingWidget({
    displayWidget,
    setDisplayWidget,
    selectedText, 
    cursorPosition, 
    documentContent,
    setPosition,
    markDownEditor
}) {
    const widgetRef = useRef(null)
    const [userQuery, setUserQuery] = useState('')
    const queryInputRef = useRef(null)
    const [textSuggestion, setTextSuggestion] = useState("")
    
    useEffect(() => {
        // Effect for handling widget visibility
        if (!displayWidget) {
            setUserQuery('');
        } else {
            // Focus on the query input when the widget is displayed
            queryInputRef.current?.focus();
        }
    }, [displayWidget]);
    
    useEffect(() => {
        // Effect for updating widget position, only if it's displayed
        if (displayWidget) {
            widgetRef.current.style.top = `${cursorPosition.y + window.scrollY + 20}px`;
            widgetRef.current.style.left = `${cursorPosition.x + window.scrollX + 20}px`;
        }
    }, [cursorPosition, displayWidget]); // Depend on displayWidget to ensure position is updated only when displayed

    // const createRange = (node, startPosition, targetPosition) => {
    //     let range = document.createRange();
    //     range.selectNode(node);
    //     range.setStart(node, 0);

    //     let pos = 0;
    //     const stack = [node];
    //     while (stack.length > 0) {
    //         const current = stack.pop();

    //         if (current.nodeType === Node.TEXT_NODE) {
    //             const len = current.textContent.length;
    //             if (pos + len >= targetPosition) {
    //                 range.setEnd(current, targetPosition - pos);
    //                 return range;
    //             }
    //             pos += len;
    //         } else if (current.childNodes && current.childNodes.length > 0) {
    //             for (let i = current.childNodes.length - 1; i >= 0; i--) {
    //                 stack.push(current.childNodes[i]);
    //             }
    //         }
    //     }

    //     // The target position is greater than
    //     // the length of the contenteditable element
    //     range.setEnd(node, node.childNodes.length);
    //     return range;
    // };

    const createRange = (node, startPosition, targetPosition) => {
        let range = document.createRange();
        range.selectNode(node);
    
        let pos = 0;
        const stack = [node];
    
        let foundStart = false;
    
        while (stack.length > 0) {
            const current = stack.pop();
    
            if (current.nodeType === Node.TEXT_NODE) {
                const len = current.textContent.length;
                
                if (!foundStart) {
                    if (pos + len >= startPosition) {
                        range.setStart(current, startPosition - pos);
                        foundStart = true;
                    }
                    pos += len;
                } else {
                    if (pos + len >= targetPosition) {
                        range.setEnd(current, targetPosition - pos);
                        return range;
                    }
                    pos += len;
                }
            } else if (current.childNodes && current.childNodes.length > 0) {
                for (let i = current.childNodes.length - 1; i >= 0; i--) {
                    stack.push(current.childNodes[i]);
                }
            }
        }
    
        // If the target position is greater than the length of the contenteditable element
        range.setEnd(node, node.childNodes.length);
        return range;
    };

    const submitQuery = () => {
        console.log("User Query: ", userQuery)
        setDisplayWidget(false)
        const contentEle = document.getElementById("mdx-editor");
        const range = createRange(contentEle, 10, 5 + 10);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        // markDownEditor.current.insertMarkdown("Hello")

        // let url = GlobalVars.ASSISTANT_DOMAIN + "/api/v1/groq/writing-assistant"
        // fetch(url, {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify({
        //         selectedText: selectedText,
        //         referenceText: "",
        //         userQuery: userQuery
        //     })
        // })
        // .then(response => response.json())
        // .then(data => {
        //     setTextSuggestion(data.response)
        // })
        // .catch(error => {
        //     console.error('Error:', error)
        // })
    }


    // create a listener for queryInputRef to listen for enter key press
    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.key === 'Enter') {
                console.log('User query:', userQuery)
                submitQuery()
            }

        }

        queryInputRef.current.addEventListener('keypress', handleKeyPress)

        return () => {
            queryInputRef.current.removeEventListener('keypress', handleKeyPress)
        }
    }, [userQuery])

    // create a window listener to close the widget if clicked outside
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (widgetRef.current && !widgetRef.current.contains(e.target)) {
                if (displayWidget) {
                    console.log('Clicked outside')
                    setDisplayWidget(false)
                }
                
            }
        }

        window.addEventListener('click', handleClickOutside)

        return () => {
            window.removeEventListener('click', handleClickOutside)
        }
    }, [])


    return (
        
        <div
            className={`absolute rounded bg-white shadow-lg p-2 w-[35vw] z-60 ${displayWidget ? 'block' : 'hidden'}`}
            ref={widgetRef}
        >
            {/* <div className="flex justify-between">
                <div className="text-sm text-gray-500">AI Suggestions</div>
                <div className="text-sm text-gray-500">
                    <button
                        className="text-sm text-gray-500"
                        onClick={() => setDisplayWidget(false)}
                    >
                        Close
                    </button>
                </div>
            </div> */}
            <div
                className="flex border border-gray-300 p-1 mt-1 rounded-sm"
            >
                <input 
                    type="text"
                    className="w-full p-1 border-0 text-sm focus:outline-none "
                    value={userQuery}
                    onChange={(e) => setUserQuery(e.target.value)}
                    placeholder="Type here and AI will suggest you the code"
                    ref={queryInputRef}
                />
                <button
                    className="hover:bg-gray-200 p-1 rounded-xl duration-200"
                    onClick={submitQuery}
                >
                    <PaperAirplaneIcon className="h-6 w-6" />
                </button>
                <button
                    className="hover:bg-gray-200 p-1 rounded-xl duration-200"
                    onClick={() => setDisplayWidget(false)}
                >
                    <XMarkIcon className="h-6 w-6" />
                </button>
            </div>
            {/* text suggestion */}
            <div>
                {textSuggestion}
            </div>
        </div>
        
    )
}