import React, { Fragment, useState, useEffect, useRef } from 'react'
import { Dialog, Transition, Menu } from '@headlessui/react'
import { XMarkIcon, MicrophoneIcon, PauseIcon, PlayIcon, TrashIcon, EllipsisVerticalIcon} from '@heroicons/react/24/outline'
import GlobalVars from '../../Config';
import './JobEditForm.css';
import SearchableMenu from '../SearchableMenu';
import { WavRecorder } from "webm-to-wav-converter";
import Select from 'react-select';
import { MultiSelect } from 'react-multi-select-component';

export default function JobEditForm({state, setEditState, jobDetail}) {
  // set useEffect to change the state of open
  useEffect(() => {
    setEditState(state);
  }, [state]);

  const [location, setLocation] = useState("");
  const [estimatedDate, setEstimatedDate] = useState("");
  const [requestedDate, setRequestedDate] = useState("");
  const [status, setStatus] = useState("");
  const [clientRemark, setClientRemark] = useState("");
  const [jobRemark, setJobRemark] = useState("");
  const [jobType, setJobType] = useState("");
  const [mainType, setMainType] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [contactDetail, setContactDetail] = useState("");
  const [staff, setStaff] = useState(null);
  const [jobPriority, setJobPriority] = useState(0);

  useEffect(() => {
    if (Object.keys(jobDetail).length !== 0) {
      
      setStaff({
        "label": jobDetail.assignedToDict.name,
        "value": jobDetail.assignedToDict.id,
      })
    }
  }, [jobDetail]);


  const organizationID = sessionStorage.getItem("organizationId");

  // get list of existing job types
  const [jobTypeList, setJobTypeList] = useState([]);
  useEffect(() => {
    let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/jobtypes?organizationID=" + organizationID;

    // create a fetch request
    fetch(url, {
      headers: {
        "Authorization": sessionStorage.getItem("idToken")
      }
    })
      .then(response => response.json())
      .then(data => {
        let jobTypeList = data["jobTypes"];

        // convert itemCode to string and update name (e.g. <itemCode>-<name>)
        for (var i = 0; i < jobTypeList.length; i++) {
          if (jobTypeList[i].hasOwnProperty('langVar')) {
            jobTypeList[i]["name"] = jobTypeList[i]["itemCode"] + "-" + jobTypeList[i]["langVar"]["tc"] + "(" + jobTypeList[i]["name"] + ")";
          } else {
            jobTypeList[i]["name"] = jobTypeList[i]["itemCode"] + "-" + jobTypeList[i]["name"];
          }
        }

        // convert to label and value
        for (var i = 0; i < jobTypeList.length; i++) {
          jobTypeList[i]["label"] = jobTypeList[i]["name"];
          jobTypeList[i]["value"] = jobTypeList[i]["_id"];
        }

        setJobTypeList(jobTypeList);
      })
      .catch(error => function() {
        // print status code
        console.log(error);
      })
  }, []);

  // get list of existing main types
  const [mainTypeList, setMainTypeList] = useState([]);
  useEffect(() => {
    let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/maintypes?lang=tc&organizationID=" + organizationID;

    // create a fetch request
    fetch(url)
      .then(response => response.json())
      .then(data => {
        // convert itemCode to string and update name (e.g. <itemCode>-<name>)
        for (var i = 0; i < data["types"].length; i++) {
          data["types"][i]["name"] = data["types"][i]["itemCode"] + "-" + data["types"][i]["name"];

          // add langVar if exists
          if (data["types"][i].hasOwnProperty('langVar')) {
            data["types"][i]["name"] = data["types"][i]["itemCode"] + "-" + data["types"][i]["langVar"]["tc"] + " (" + data["types"][i]["name"] + ")";
          }

        } 

        // set list
        setMainTypeList(data["types"]);
      })
      .catch(error => function() {
        // print status code
        console.log(error);
      })
  }, []);

  // get list of existing locations
  const [locationList, setLocationList] = useState([]);
  useEffect(() => {
    let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/locations?lang=tc&organizationID=" + organizationID;

    // create a fetch request
    fetch(url, {
      headers: {
        "Authorization": sessionStorage.getItem("idToken")
      }
    })
      .then(response => response.json())
      .then(data => {
        // Filter out items where isActive is false
        data["locations"] = data["locations"].filter(item => item["isActive"] !== false);

        // Iterate over locations to modify each item
        data["locations"] = data["locations"].map(item => {
          // Check if langVar exists, if so, append its 'tc' value to the name if available
          if (item.hasOwnProperty("langVar") && item["langVar"].hasOwnProperty("tc")) {
            item["name"] += " (" + item["langVar"]["tc"] + ")";
          }
          // Return the modified item in the specified format, changing 'value' to item["_id"]
          return {'label': item["name"], 'value': item["_id"]};
        });

        setLocationList(data['locations']);
      })
      .catch(error => function() {
        // print status code
        console.log(error);
      })
  }, []);

  // handle priority change
  const priorityMapping = { '無': 0, '低': 1, '中': 2, '高': 3 };
  const inversePriorityMapping = { 0: '無', 1: '低', 2: '中', 3: '高' };

  const handlePriorityChange = (newPriority) => {
    setJobPriority(priorityMapping[newPriority]);
  };

  const getButtonClass = (value) => {
    return jobPriority === priorityMapping[value]
      ? "relative inline-flex items-center px-3 py-2 text-sm font-semibold text-white bg-indigo-600 ring-1 ring-inset ring-indigo-300 hover:bg-indigo-700 focus:z-10"
      : "relative inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10";
  };

  // populate job detail
  useEffect(() => {
    if (jobDetail.hasOwnProperty('locationIDs')) {
      // jobDetail.locationIDs is a list of location ids, match each locationID with locationList.value to return a list of matched objects from locationList
      let locationObject = jobDetail.locationIDs.reduce((acc, id) => {
        let matchingLocations = locationList.filter(item => item.value.includes(id));
        return acc.concat(matchingLocations);
      }, []);
      
      // set Location
      setLocation(locationObject);
    } else if (jobDetail.hasOwnProperty('locationID')) {
      // find the location object that matches jobDetail.locationID
      let locationObject = locationList.find(item => item.value.includes(jobDetail.locationID));
      // set Location
      setLocation(locationObject);
    }
    
    
    if (jobDetail.hasOwnProperty('requestedTime')) {
        // console.log("requestedTime: ", jobDetail.requestedTime);
        setRequestedDate(jobDetail.requestedTime);
    }

    if (jobDetail.hasOwnProperty('meta')) {
        if (jobDetail['meta'].hasOwnProperty('estimatedDate')) {
            setEstimatedDate(jobDetail['meta'].estimatedDate);
        } else if (jobDetail.hasOwnProperty("createdAt")) {
            let dateValue = jobDetail["createdAt"].split(" ")[0];
            setEstimatedDate(dateValue);
        }

        setClientRemark(jobDetail.meta.clientRemark);
        setJobRemark(jobDetail.meta.jobRemark);

        if (jobDetail['meta'].hasOwnProperty('contactPerson')) {
      
          setContactPerson(jobDetail['meta'].contactPerson);
        }
    
        if (jobDetail['meta'].hasOwnProperty('contactDetail')) {
          setContactDetail(jobDetail['meta'].contactDetail);
        }
    }

    if (jobDetail.hasOwnProperty('status')) {
        setStatus(jobDetail.latestStatus["status"]);
    }

    if (jobDetail.hasOwnProperty('typeIDs')) {
      // jobDetail.typeName is a list and match each typeName with jobTypeList.label to return a list of matched objects from jobTypeList
      let jobTypeObject = jobDetail.typeIDs.reduce((acc, id) => {
        let matchingJobTypes = jobTypeList.filter(item => item.value.includes(id));
        return acc.concat(matchingJobTypes);
      }, []);
      
      // set Job Type
      setJobType(jobTypeObject);
    } else if (jobDetail.hasOwnProperty('typeID')) {
      // find the job type object that matches jobDetail.typeID
      let jobTypeObject = jobTypeList.find(item => item.value.includes(jobDetail.typeID));
      // set Job Type
      setJobType(jobTypeObject);
    }
    
    if (jobDetail.hasOwnProperty('mainTypeName')) {
      console.log(jobDetail['mainTypeName']);
      // find the main type object that matches jobDetail.mainTypeName
      let mainTypeObject = mainTypeList.find(item => item.name.includes(jobDetail.mainTypeName));
      // set Main Type
      setMainType(mainTypeObject);
    }

    if (jobDetail.hasOwnProperty('jobPriority')) {
      setJobPriority(jobDetail.jobPriority);
    }

  }, [jobDetail, state, mainTypeList, jobTypeList, locationList]);

  // handle save event 
  const handleSave = (e) => {
    // convert text to 儲存中
    e.target.innerText = "儲存中";
    e.target.disabled = true;

    // input validation by checking if required fields are empty (i.e. location, typeID)
    if (location === "" || jobType === "") {
      alert("請填寫必填欄位 (工作地點, 工作要求)");
      return;
    }


    let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/jobs?organizationID=" + organizationID;

    // convert location to a list of id by extracting value from each object
    let locationIdList = location.map(item => item.value);

    // convert typeID to a list of id by extracting value from each object
    let jobTypeIdList = jobType.map(item => item.value);

    // check that either locationIdList or jobTypeIdList length can be greater than one
    if (locationIdList.length > 1 && jobTypeIdList.length > 1) {
      alert("只可以選擇多個工作或是多個地點, 而不能同時間多個工作和地點");

      // reset button text
      e.target.innerText = "儲存";
      e.target.disabled = false;
      return;
    }
    

    // create submit data
    let submitData = {
      "jobID": jobDetail._id,
      "organizationID": organizationID,
      "requestedTime": requestedDate,
      "locationIDs": locationIdList,
      "assignedTo": staff['value'],
      "meta": {
        "clientRemark": clientRemark,
        "jobRemark": jobRemark,
        "estimatedDate": estimatedDate,
        "mainType": mainType._id,
      },
      "typeIDs": jobTypeIdList,
      "jobPriority": jobPriority,
    }

    // check if requestedTime is null and update requestedTime format to %Y-%m-%dT%H:%M
    if (submitData["requestedTime"] != null) {
      submitData["requestedTime"] = submitData["requestedTime"].replace(" ", "T");
    }

    // create PUT request 
    fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Authorization": sessionStorage.getItem("idToken")
      },
      body: JSON.stringify(submitData)
    })
      .then(response => response.json())
      .then(data => {
        // upload audio file if it exists
        // console.log(audioUrl != null);
        if (audioUrl != null) {
          // create form data 
          const formData = new FormData();
          formData.append('audio', audioBlob);

          // // send form data to server (endpoing: /api/v1/jobs/audio-note)
          let url = GlobalVars.BACKEND_DOMAIN + '/api/v1/jobs/audio-note?organizationID=' + sessionStorage.getItem('organizationId') + '&jobID=' + jobDetail._id;
          fetch(url, {
              method: 'POST',
              body: formData
          }).then(response => response.json())
          .then(data => {
            alert("工作已更新");

            // close the form
            setEditState(false);
  
            // refresh the page
            window.location.reload();
          }).catch(error => {
              console.error(error);
          });
        } else {
          alert("工作已更新");

          // close the form
          setEditState(false);

          // refresh the page
          window.location.reload();
        }
        
      })
      .catch(error => function() {
        // print status code
        alert("工作更新失敗");
      })
  }

  // changes row numbers for textarea when focus
  const defaultRows = 2;
  const focusedRows = 15;
  const handFocus = (e) => {
    e.target.rows = focusedRows;
  };

  // changes row numbers for textarea when blur
  const handleBlur = (e) => {
    e.target.rows = defaultRows;
  };

  // set up audio recording component ref 
  const [audioBlob, setAudioBlob] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);

  // custom value renderer for multi select
  const customValueRenderer = (selected, _options) => {
    if (selected.length === 0) {
      return "";
    } else if (selected.length === 1) {
      if (selected[0].label.length > 30) {
        return selected[0].label.slice(0, 30) + "...";
      } 
      return selected[0].label
    }
    return "已選擇 " + selected.length + " 項";
  }

  return (
    <Transition.Root show={state} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setEditState} id="job-edit-form">
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            修改工作詳細
                            <span className={`ml-2 text-center min-w-[60px] inline-flex items-center justify-center rounded-md ring-1 ring-inset text-xs font-medium px-2 py-1 ${status}`}>
                                {status}
                            </span>
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                              onClick={() => setEditState(false)}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6">
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-200">
                            <div className="space-y-6 pb-5 pt-6">
                              <div id="estimated_date_wrapper">
                                <label htmlFor="estimated_date_input" className="block text-sm font-medium leading-6 text-gray-900">
                                  預計工作日期
                                </label>
                                <div className="mt-2">
                                  <input
                                    type="date"
                                    name="estimated_date"
                                    id="estimated_date_input"
                                    value={estimatedDate}
                                    onChange={e => setEstimatedDate(e.target.value)}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                                {/* <p className="mt-2 text-sm text-gray-500" id="email-description">
                                  We'll only use this for spam.
                                </p> */}
                              </div>
                              <div id="requested_date_wrapper">
                                <label htmlFor="requested_date_input" className="block text-sm font-medium leading-6 text-gray-900">
                                  客戶要求工作時間
                                </label>
                                <div className="mt-2">
                                  <input
                                    type="datetime-local"
                                    name="requested_date"
                                    id="requested_date_input"
                                    value={requestedDate}
                                    onChange={e => setRequestedDate(e.target.value)}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                                {/* <p className="mt-2 text-sm text-gray-500" id="email-description">
                                  We'll only use this for spam.
                                </p> */}
                              </div>
                              <div id="location_wrapper"> 
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                  客戶/地點
                                </label>
                                <div className="mt-2">
                                  {/* <SearchableMenu list={locationList} selectedItem={location} setSelectedItem={setLocation} /> */}
                                  <MultiSelect
                                    options={locationList}
                                    value={location}
                                    onChange={setLocation}
                                    labelledBy="Select"
                                    overrideStrings={{
                                      selectSomeItems: "選擇地點",
                                      allItemsAreSelected: "所有地點已選",
                                      selectAll: "選擇所有",
                                      search: "搜尋",
                                    }}
                                    className='text-sm'
                                    valueRenderer={customValueRenderer}
                                  />
                                  {/* display count of selection */}
                                  <p className="text-xs text-gray-500 mt-1">{location.length} 個地點已選</p>
                                </div>
                              </div>
                              <div id="client_remark_wrapper">
                                <label htmlFor="client_remark_input" className="block text-sm font-medium leading-6 text-gray-900">
                                  客戶需求
                                </label>
                                <div className="mt-2">
                                  <textarea
                                    id="client_remark_input"
                                    name="client_remark"
                                    rows={defaultRows}
                                    onFocus={handFocus}
                                    onBlur={handleBlur}
                                    className="duration-300 block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
                                    placeholder=""
                                    value={clientRemark}
                                    onChange={e => setClientRemark(e.target.value)}
                                  />
                                </div>
                                {/* <p className="mt-2 text-sm text-gray-500" id="email-description">
                                  We'll only use this for spam.
                                </p> */}
                              </div>
                              <div id="job_remark_wrapper">
                                <div className='flex items-center justify-between'>
                                  <label htmlFor="job_remark_input" className="block text-sm font-medium leading-6 text-gray-900">
                                    工作備忘
                                  </label>
                                  <VoiceRecording setAudioBlob={setAudioBlob} setAudioURL={setAudioUrl} audioURL={audioUrl} />
                                </div>
                                <div className="mt-2">
                                  <textarea
                                    id="job_remark_input"
                                    name="job_remark"
                                    rows={defaultRows}
                                    onFocus={handFocus}
                                    onBlur={handleBlur}
                                    className="duration-300 block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
                                    placeholder=""
                                    value={jobRemark}
                                    onChange={e => setJobRemark(e.target.value)}
                                  />
                                </div>
                                {/* <p className="mt-2 text-sm text-gray-500" id="email-description">
                                  We'll only use this for spam.
                                </p> */}
                              </div>
                              <div id="job_priority_wrapper">
                                <label htmlFor="job_priority_input" className="block text-sm font-medium leading-6 text-gray-900">
                                  優先級別
                                </label>
                                <div className="mt-2">
                                  <span className="isolate inline-flex rounded-md shadow-sm">
                                    {Object.keys(priorityMapping).map((value) => (
                                      <button
                                        key={value}
                                        type="button"
                                        className={getButtonClass(value)}
                                        onClick={() => handlePriorityChange(value)}
                                      >
                                        {value}
                                      </button>
                                    ))}
                                  </span>
                                </div>
                              </div>

                              <hr></hr>
                              <div id="product_type_wrapper"> 
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                  主類別
                                </label>
                                <div className="mt-2">
                                  {/* <input
                                    type="text"
                                    name="product_type"
                                    id="product_type_input"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    value={mainType}
                                  /> */}
                                  <SearchableMenu list={mainTypeList} selectedItem={mainType} setSelectedItem={setMainType} />
                                </div>
                              </div>
                              <div id="job_type_wrapper"> 
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                  工作要求
                                </label>
                                <div className="mt-2">
                                  {/* <input
                                    type="text"
                                    name="job_type"
                                    id="job_type_input"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    value={jobType}
                                  /> */}
                                  {/* <SearchableMenu list={jobTypeList} selectedItem={jobType} setSelectedItem={setJobType} /> */}
                                  <MultiSelect
                                    options={jobTypeList}
                                    value={jobType}
                                    onChange={setJobType}
                                    labelledBy="Select"
                                    overrideStrings={{
                                      selectSomeItems: "選擇工作要求",
                                      allItemsAreSelected: "所有工作要求已選",
                                      selectAll: "選擇所有",
                                      search: "搜尋",
                                    }}
                                    className='text-sm'
                                    valueRenderer={customValueRenderer}
                                  />
                                  {/* display count of selection */}
                                  <p className="text-xs text-gray-500 mt-1">{jobType.length} 個工作要求已選</p>
                                </div>
                              </div>
                              <hr></hr>
                              {/* assignedTo */}
                              <AssignedToList mainType={mainType} staff={staff} setStaff={setStaff} />

                              <hr></hr>
                              <div id="contact_person_wrapper">
                                <label htmlFor="contact_person_input" className="block text-sm font-medium leading-6 text-gray-900">
                                  聯絡人
                                </label>
                                <div className="mt-2">
                                  <input
                                    type="text"
                                    name="contact_person"
                                    id="contact_person_input"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    value={contactPerson}
                                    onChange={e => setContactPerson(e.target.value)}
                                  />
                                </div>
                                {/* <p className="mt-2 text-sm text-gray-500" id="email-description">
                                  We'll only use this for spam.
                                </p> */}
                              </div>
                              <div id="contact_detail_wrapper">
                                <label htmlFor="contact_detail_input" className="block text-sm font-medium leading-6 text-gray-900">
                                  聯絡資料
                                </label>
                                <div className="mt-2">
                                  <input
                                    type="text"
                                    name="contact_detail"
                                    id="contact_detail_input"
                                    value={contactDetail}
                                    onChange={e => setContactDetail(e.target.value)}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                                {/* <p className="mt-2 text-sm text-gray-500" id="email-description">
                                  We'll only use this for spam.
                                </p> */}
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                        onClick={() => setEditState(false)}
                      >
                        取消
                      </button>
                      <button
                        type="submit"
                        className="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                        onClick={handleSave}
                      >
                        儲存
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

// Voice recording component 
function VoiceRecording({setAudioBlob, setAudioURL, audioURL}) {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = new WavRecorder();
  }, []);

  // states for audio recording
  // const [audioURL, setAudioURL] = useState(null); 
  const [audioDuration, setAudioDuration] = useState(null); 
  const [recordActive, setRecordActive] = useState(false);
  const [recordSeconds, setRecordSeconds] = useState(0);

  // handle record start
  const handleRecordStart = (e) => {
    console.log("start recording");
    setRecordActive(true);

    ref.current.start();

    // clear audio url
    setAudioURL(null);
  };

  // handle cancel record
  const handleRecordCancel = (e) => {
    console.log("cancel recording");
    setRecordActive(false);

    ref.current.stop();

    // reset recordSeconds
    setRecordSeconds(0);

    // clear audio url
    setAudioURL(null);
  };

  // handle pause record
  const handlePauseRecord = (e) => {
    console.log("pause recording");
    setRecordActive(false);

    ref.current.stop();

    setTimeout(() => {
      var audio = ref.current.getBlob(true, { sampleRate:  96000 });

      audio.then((audio) => {
        console.log(audio);
        let audioURL = URL.createObjectURL(audio);
        setAudioURL(audioURL);

        // alert users the recording will be uploaded when they save the job
        alert("錄音已儲存, 請按儲存按鈕以儲存錄音");

        // set audio blob
        setAudioBlob(audio);

      });
    }, 1000);
  }

  // handle play record
  const handlePlayRecord = (e) => {
    console.log("play recording");
    audioRef.current.play();

  }

  // effect for handling timer start
  useEffect(() => {
    let interval = null;
    if (recordActive) {
      interval = setInterval(() => {
        setRecordSeconds(recordSeconds => recordSeconds + 1);
      }, 1000);
    } else if (!recordActive && recordSeconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [recordActive, recordSeconds]);

  // set up audio player ref
  const audioRef = useRef();

  return (
    <div
      className={`bg-[#2A2A2A] rounded-md ${recordActive || audioURL ? 'w-[60%] px-3 justify-between': 'w-[30px] justify-center'} h-[30px] flex items-center duration-500`}
    > 
      {recordActive ? 
        <>
          <span className='text-white'>{Math.floor(recordSeconds / 60)}:{("0" + (recordSeconds % 60)).slice(-2)}</span>
          <div
            className='flex items-center justify-between'
          >
            <button
              onClick={handlePauseRecord}
            >
              <PauseIcon className="h-5 w-8 stroke-white" aria-hidden="true" />
            </button>
            <button
              onClick={handleRecordCancel}
            >
              <XMarkIcon className="ml-4 h-5 w-5 stroke-white" aria-hidden="true" />
            </button>
          </div>
        </>
      :
        <>
          {audioURL ?
            <>
              <span className='text-white'>{Math.floor(recordSeconds / 60)}:{("0" + (recordSeconds % 60)).slice(-2)}</span>
              <AudioRecordingOptions handlePlayRecord={handlePlayRecord} handleRecordCancel={handleRecordCancel} />
            </>
          :
            <button
              onClick={handleRecordStart}
            >
              <MicrophoneIcon className="h-5 w-5 stroke-white" aria-hidden="true" />
            </button>
          }
        </>
      }
      <audio 
        src={audioURL} controls 
        className='hidden'
        ref={audioRef}
      />
    </div>
  )
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

// audio recording options
function AudioRecordingOptions({handlePlayRecord, handleRecordCancel}) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="flex items-center rounded-full text-white hover:text-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
          <span className="sr-only">Open options</span>
          <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <a
                  onClick={handlePlayRecord}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  播放錄音
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  onClick={handleRecordCancel}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  刪除錄音
                </a>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

// assignedTo list component. 
function AssignedToList({mainType, staff, setStaff}) {
  // fetch existing list of staff
  const [staffListOptions, setStaffListOptions] = useState([]);

  // create staff list options 
  useEffect(() => {
    let organizationID = sessionStorage.getItem("organizationId");
    let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/users/retrieve-with-main-type?organizationID=" + organizationID;
    
    // create a fetch request
    fetch(url)
      .then(response => response.json())
      .then(rawData => {
        let data = rawData["users"];

        // convert data to [{label: <name>, options: [{label: <name>, value: <_id>}, ...]}, ...]
        let newStaffListOptions = [];
        for (var key in data) {
          let staffList = data[key];
          let newStaffList = staffList.map((staff) => {
            let group = staff.group || ''; // handle undefined group
            return {
              label: staff.name + (group ? ` (${group})` : ''), // omit parenthesis if group is empty
              value: staff._id
            }
          });
          newStaffListOptions.push({
            label: key,
            options: newStaffList
          });
        }

        setStaffListOptions(newStaffListOptions);


        // find the staff object from the list and set staff
        for (var i = 0; i < newStaffListOptions.length; i++) {
          let staffList = newStaffListOptions[i]["options"];

          // go through each staff in the list
          for (var j = 0; j < staffList.length; j++) {
            let staff = staffList[j];
          }
        }

      })
      .catch(error => function() {
        // print status code
        console.log(error);
      })
  }, []);

  // handle assignTo selection
  const handleAssignToSelection = (selectedOption) => {
    // return if selectedOption is empty
    if (selectedOption === "" || selectedOption === undefined || selectedOption === null) {
      return;
    }

    // unchecked the checkbox if staff is selected
    document.getElementById('hold-off-assignment').checked = false;

    // find object containing the selectedOption
    for (var i = 0; i < staffListOptions.length; i++) {
      let staffList = staffListOptions[i]["options"];

      // go through each staff in the list
      for (var j = 0; j < staffList.length; j++) {
        let staff = staffList[j];

        // if current user is found, set the main type and staff
        if (staff.value === selectedOption.value) {
          setStaff(staff);

          return;
        }
      }
    }

    
  };


  // filter staff list based on mainType
  useEffect(() => {
    if (mainType === "" || mainType === undefined) {
      return;
    }

    let mainTypeName = mainType['langVar']['tc'];
    
    // reorder the list so the mainType is at the top and the rest is sorted alphabetically
    let newStaffListOptions = [];
    for (var i = 0; i < staffListOptions.length; i++) {
      let staffList = staffListOptions[i]["options"];

      // if the mainType is found, add to newStaffListOptions
      if (staffListOptions[i]["label"].includes(mainTypeName)) {
        newStaffListOptions.push({
          label: staffListOptions[i]["label"],
          options: staffList
        });
      }
    }

    // sort the rest of the list alphabetically
    for (var i = 0; i < staffListOptions.length; i++) {
      let staffList = staffListOptions[i]["options"];

      // if the mainType is found, add to newStaffListOptions
      if (!staffListOptions[i]["label"].includes(mainTypeName)) {
        newStaffListOptions.push({
          label: staffListOptions[i]["label"],
          options: staffList
        });
      }
    }

    // update staffListOptions
    setStaffListOptions(newStaffListOptions);

  }, [mainType]);


  return(
    <>
      <label htmlFor="staff_input" className="block text-sm font-medium leading-6 text-gray-900 mb-2">施工人員</label>
      {/* selector for staff */}
      <Select 
        options={staffListOptions}
        value={staff}
        onChange={handleAssignToSelection}
      />
      {/* checkbox for holding off assignment */}
      <div className="relative flex items-start mt-3">
        <div className="flex h-6 items-center">
          <input
            id="hold-off-assignment"
            name="hold-off-assignment"
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
            onClick={() => {
              // clear staff if checkbox is checked
              if (staff.value !== "") {
                setStaff("");
              }
            }}
          />
        </div>
        <div className="ml-3 text-sm leading-6">
          <label htmlFor="hold-off-assignment" className="font-medium text-gray-900">
            暫時不分配
          </label>
          <p id="hold-off-assignment-description" className="text-gray-500">
            <span className="sr-only">暫時不分配 </span>工作將會保持在待分配狀態
          </p>
        </div>
      </div>
    </>
  )
}


