// This model is used for display a status change modals to user to indicate the status of the request (It can be a success, failure or other statuses)

import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, ExclamationCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'

export default function StatusModals({setOpen, open, status, title, description, btnText}) {
//   const [open, setOpen] = useState(true)

    let statusIcon;
    if (status === 'success') {
        statusIcon = (<div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100"><CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" /></div>);
    } else if (status === 'warning') {
        statusIcon = (<div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100"><ExclamationTriangleIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" /></div>);
    } else if (status === 'error') {
        statusIcon = (<div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100"><ExclamationCircleIcon className="h-6 w-6 text-red-600" aria-hidden="true" /></div>);
    } else if (status == 'loading') {
        statusIcon = (<div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100"><svg className="animate-spin h-6 w-6 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" /><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"/></svg></div>);
    }

    return (
        <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
            <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                    <div>
                        {statusIcon}
                        <div className="mt-3 text-center sm:mt-5">
                            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                            {title}
                            </Dialog.Title>
                            <div className="mt-2">
                            <p className="text-sm text-gray-500">
                                {description}
                            </p>
                            </div>
                        </div>
                        </div>
                        <div className="mt-5 sm:mt-6">
                        <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={() => setOpen(false)}
                            disabled={status === 'loading'}
                        >
                            {btnText}
                        </button>
                    </div>
                </Dialog.Panel>
                </Transition.Child>
            </div>
            </div>
        </Dialog>
        </Transition.Root>
    )
}
