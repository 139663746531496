import ApplicationShell from "../../Components/ApplicationShell";
import GlobalVars from "../../Config";
import { useState, useRef, useEffect, Fragment } from "react";
import '@mdxeditor/editor/style.css'
import { 
    MDXEditor, 
    headingsPlugin, 
    UndoRedo, 
    BoldItalicUnderlineToggles, 
    toolbarPlugin, 
    InsertTable, 
    tablePlugin,  
    ListsToggle, 
    listsPlugin,
    BlockTypeSelect,
    Separator,
} from '@mdxeditor/editor'
import './CreateNewDocument.css'
import ChatInterfaceSlideOver from "../../Components/ChatInterfaceSlideOver";
import {
    ChatBubbleLeftRightIcon,
    CheckIcon,
    ClipboardDocumentCheckIcon
} from '@heroicons/react/24/outline'
import { Dialog, Transition } from '@headlessui/react'
import AIFloatingWidget from "../../Components/AIFloatingWidget/AIFloatingWidget";

export default function CreateNewDocument() {
    // set page title 
    document.title = "新增文章";

    // states
    const [documentTitle, setDocumentTitle] = useState("Untitled Document");
    const [documentContent, setDocumentContent] = useState(` `);
    const markDownEditor = useRef(null);

    // check if there's a docId in the url
    // if there is, fetch the document content and set it in the state
    // if there isn't, set the state to empty
    const [docId, setDocId] = useState(null);
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const docId = urlParams.get('docId');
        setDocId(docId);
        if (docId) {
            // fetch the document content
            fetch(GlobalVars.BACKEND_DOMAIN + "/api/v1/knowledge-base/documents/manage?docId=" + docId + "&organizationID=" + localStorage.getItem("organizationId"))
                .then((res) => res.json())
                .then((data) => {
                    
                    setDocumentTitle(data['document']['title']);
                    setDocumentContent(data['document']['content']);
                    
                });
        }
    }, []);

    // handle create new document, by posting the new document to the backend
    const handleCreateNewDocument = () => {

        let postUrl = GlobalVars.BACKEND_DOMAIN + "/api/v1/knowledge-base/documents/manage";
        let postData = {
            title: documentTitle,
            content: documentContent,
            organizationID: localStorage.getItem("organizationId"),
            userID: localStorage.getItem("userid")
        };

        // check if docId exists, if it does, add it to the postData
        if (docId) {
            postData['documentID'] = docId;
        }

        let fetchMethod = docId ? "PUT" : "POST";

        fetch(postUrl, {
            method: fetchMethod,
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(postData)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            return response.json()
        })
        .then(data => {
            alert("檔案已儲存");
            window.location.href = "/knowledge-base";
        })
        .catch(error => {
            console.error(error);
        });

        
    }

    // store the document content in the state
    const handleDocumentContentChange = (content) => {
        setDocumentContent(content);
    }

    // initialize the mark down content
    const [initialized, setInitialized] = useState(false);
    
    const activeRef = useRef(null);
    useEffect(() => {
        if (markDownEditor.current && !initialized && documentContent !== ` `) {
            markDownEditor.current.setMarkdown(documentContent);
            setInitialized(true);
        }

    }, [markDownEditor.current, documentContent]);

    // external markdown pasting dialog
    const [showExternalMarkdownDialog, setShowExternalMarkdownDialog] = useState(false);

    // cursor position using wind
    const [cursorPosition, setCursorPosition] = useState({x: 0, y: 0});
    const [selectedText, setSelectedText] = useState("");
    const editorContainer = useRef(null);
    const [cursorPositionByCharacters, setCursorPositionByCharacters] = useState(0);


    const getCaretCoordinates = () => {
      const div = editorContainer.current;
      if (!div) return;
  
      const selection = window.getSelection();
      if (!selection.rangeCount) return;
  
      const range = selection.getRangeAt(0).cloneRange();
      const rect = range.getClientRects()[0];
  
      if (!rect) return;
  
      const caretX = rect.left;
      const caretY = rect.top;
  
      console.log(`Caret position - X: ${caretX}, Y: ${caretY}`);

      setCursorPosition({x: caretX, y: caretY});
      setSelectedText(selection.toString());

      // get the cursor position by characters
      const clonedRange = range.cloneRange();
      clonedRange.selectNodeContents(document.getElementById("mdx-editor"));
      clonedRange.setEnd(range.endContainer, range.endOffset);
      setCursorPositionByCharacters(clonedRange.toString().length);
      console.log(clonedRange.toString().length - 10);

    };

    const [displayWidget, setDisplayWidget] = useState(false)
    // create a keyboard shortcut to start the assistance (cmd + shift + s in mac and ctrl + shift + s in windows)
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 's' && (e.ctrlKey || e.metaKey) && e.shiftKey) {
                console.log("ctrl + shift + s");
                setDisplayWidget(true);
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    const createRange = (node, targetPosition) => {
        let range = document.createRange();
        range.selectNode(node);
        range.setStart(node, 0);

        let pos = 0;
        const stack = [node];
        while (stack.length > 0) {
            const current = stack.pop();

            if (current.nodeType === Node.TEXT_NODE) {
                const len = current.textContent.length;
                if (pos + len >= targetPosition) {
                    range.setEnd(current, targetPosition - pos);
                    return range;
                }
                pos += len;
            } else if (current.childNodes && current.childNodes.length > 0) {
                for (let i = current.childNodes.length - 1; i >= 0; i--) {
                    stack.push(current.childNodes[i]);
                }
            }
        }

        // The target position is greater than
        // the length of the contenteditable element
        range.setEnd(node, node.childNodes.length);
        return range;
    };

    const setPosition = (targetPosition) => {
        const contentEle = document.getElementById("mdx-editor");
        const range = createRange(contentEle, targetPosition + 10);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
    };


    return (
        <>
            <ApplicationShell>
                <PageHeading documentTitle={documentTitle} setDocumentTitle={setDocumentTitle} handleCreateNewDocument={handleCreateNewDocument} />
                <div
                    id="mdx-editor"
                    className="border rounded h-full"
                    ref={editorContainer}
                    onMouseUp={getCaretCoordinates}
                >
                    <MDXEditor 
                        ref={markDownEditor}
                        onChange={handleDocumentContentChange}
                        contentEditableClassName="prose"
                        markdown={""}
                        plugins={[
                            toolbarPlugin({
                                toolbarContents: () => (
                                    <>
                                        {' '}
                                        <UndoRedo />
                                        <Separator />
                                        <BoldItalicUnderlineToggles />
                                        <Separator />
                                        <InsertTable />
                                        <Separator />
                                        <ListsToggle />
                                        <Separator />
                                        <BlockTypeSelect />
                                        <Separator />
                                        <StartAssistanceBtn markDownEditor={markDownEditor} />
                                        <button
                                            className="ml-3"
                                            onClick={() => setShowExternalMarkdownDialog(true)}
                                        >
                                            <ClipboardDocumentCheckIcon className="w-6 h-6 text-gray-600" onClick={() => setShowExternalMarkdownDialog(true)} />
                                        </button>
                                    </>
                                )
                            }),
                            headingsPlugin(),
                            tablePlugin(),
                            listsPlugin()
                        ]}
                    />
                </div>
            </ApplicationShell>
            <PastingExternalMarkdown open={showExternalMarkdownDialog} setOpen={setShowExternalMarkdownDialog} markDownEditor={markDownEditor} />
            <AIFloatingWidget 
              displayWidget={displayWidget}
              setDisplayWidget={setDisplayWidget}
              selectedText={selectedText} 
              cursorPosition={cursorPosition} 
              documentContent={documentContent} 
              setPosition={setPosition}
              markDownEditor={markDownEditor}
            />
        </>
    );
}

// page headings with a modifiable title (using an input field) and a save button
function PageHeading({documentTitle, setDocumentTitle, handleCreateNewDocument}) {

    return (
        <div className="md:flex md:items-center md:justify-between mt-2 mb-2">
        <div className="min-w-0 flex-1">
            <input
                type="text"
                className="rounded outline-none border-none text-xl font-bold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight"
                value={documentTitle}
                onChange={(e) => setDocumentTitle(e.target.value)}
                
            />
        </div>
          <div className="mt-4 flex md:ml-4 md:mt-0">
            {/* <button
              type="button"
              className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Edit
            </button> */}
            <button
              type="button"
              onClick={handleCreateNewDocument}
              className="ml-3 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              儲存
            </button>
          </div>
        </div>
      )
}

// start assistance button
function StartAssistanceBtn({markDownEditor}) {
    const handleClick = () => {
        console.log("clicked")

        // markDownEditor.current.insertMarkdown("clicked");

    }

    return (
        <button
            onClick={handleClick}
        >
            <ChatBubbleLeftRightIcon className="w-6 h-6 text-gray-600" />
        </button>
    )
}

// pasting external markdown string
function PastingExternalMarkdown({open, setOpen, markDownEditor}) {
  
    const cancelButtonRef = useRef(null)
    const [externalMarkdown, setExternalMarkdown] = useState("This is a sample markdown.")

    const handlePasteExternalMarkdown = () => {
        // return if externalMarkdown is empty
        if (externalMarkdown === "") {
            alert("請輸入外部 Markdown");
            return;
        }

        markDownEditor.current.insertMarkdown("\n" + externalMarkdown);

        setOpen(false);
    }
  
    return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog className="relative z-50" initialFocus={cancelButtonRef} onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
  
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        貼上外部 Markdown
                      </Dialog.Title>
                      {/* <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eius aliquam laudantium explicabo
                          pariatur iste dolorem animi vitae error totam. At sapiente aliquam accusamus facere veritatis.
                        </p>
                      </div> */}
                      <div
                        className="mt-5 sm:mt-6"
                      >
                        <textarea
                            className="border rounded w-full h-40"
                            value={externalMarkdown}
                            onChange={(e) => setExternalMarkdown(e.target.value)}
                        >
                        </textarea>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                      onClick={handlePasteExternalMarkdown}
                    >
                      貼上
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
  }



