import React, { useState, useEffect } from 'react';
import GlobalVars from '../../../../Config';



export default function NewUser({email, setEmail, type, setType, group, setGroup, name, setName, phone, setPhone, userRole, setUserRole}) {
    
    const [availUserTypes, setAvailUserTypes] = useState([{name: '工作人員', description: '工作人員'}]);
    const [availUserGroups, setAvailUserGroups] = useState([{name: '工作人員', description: '工作人員'}]);
  
    // fetch data from backend for existing types and groups
    useEffect(() => {
        // fetch data from backend for types
        let url = GlobalVars.BACKEND_DOMAIN + '/api/v1/user-types?organizationID=' + sessionStorage.getItem("organizationId");
        fetch(url)
        .then(response => response.json())
        .then(data => {
            
            setAvailUserTypes(data['types']);
        });
  
        // fetch data from backend for groups
        let url2 = GlobalVars.BACKEND_DOMAIN + '/api/v1/user-groups?organizationID=' + sessionStorage.getItem("organizationId");
        fetch(url2)
        .then(response => response.json())
        .then(data => {
            
            setAvailUserGroups(data['groups']);
        });
    }, []);

    // verify if the current user is the same as the user being edited
    const [isCurrentUser, setIsCurrentUser] = useState(false);
    useEffect(() => {
        let url = GlobalVars.BACKEND_DOMAIN + '/api/v1/users?userID=' + localStorage.getItem("userid");
        fetch(url)
        .then(response => response.json())
        .then(data => {
            if (data['user']['meta']['email'] === email) {
                setIsCurrentUser(true);
            } else {
                setIsCurrentUser(false);
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }, [email]);

  
    return (
        <>
            <label 
            htmlFor="locationName"
            className='block text-sm font-medium text-gray-700'
            >
                電郵地址:
                :</label>
            <input 
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={`mt-1 block w-full rounded-md border-0 py-1.5 pr-10  ring-1 ring-insering-gray-300 placeholder:text-gray-300 text-gray-900 focus:ring-gray-500 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ring-gray-500`}
            required />
  
            <label 
            htmlFor="type"
            className='block text-sm font-medium text-gray-700 mt-4'
            >用戶類別:</label>
            <select
            id="type"
            value={type}
            onChange={(e) => {
              setType(e.target.value)
            }}
            className={`mt-1 block w-full rounded-md border-0 py-1.5 pr-10  ring-1 ring-insering-gray-300 placeholder:text-gray-300 text-gray-900 focus:ring-gray-500 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ring-gray-500`}
            required>
                <option value="">請選擇</option>
                {availUserTypes.map((availableType) => (
                    <option key={availableType._id} value={availableType._id}>{availableType.name}</option>
                ))}
            </select>
            <label
            htmlFor="group" 
            className='block text-sm font-medium text-gray-700 mt-4'
            >用戶群組:</label>
            <select
            onChange={(e) => setGroup(e.target.value)}
            value={group}
            id="group"
            className={`mt-1 block w-full rounded-md border-0 py-1.5 pr-10  ring-1 ring-insering-gray-300 placeholder:text-gray-300 text-gray-900 focus:ring-gray-500 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ring-gray-500`}
            required>
                <option value="">請選擇</option>
                {availUserGroups.map((availableGroup) => (
                    <option key={availableGroup._id} value={availableGroup._id}>{availableGroup.name}</option>
                ))}
            </select>
            <label
              htmlFor="name"
              className='block text-sm font-medium text-gray-700 mt-4'
            >名稱:</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className={`mt-1 block w-full rounded-md border-0 py-1.5 pr-10  ring-1 ring-insering-gray-300 placeholder:text-gray-300 text-gray-900 focus:ring-gray-500 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ring-gray-500`}
            />
            <label
              htmlFor="phone"
              className='block text-sm font-medium text-gray-700 mt-4'
            >電話號碼(香港8位數字號碼, 例98765432):</label>
            <input
              type="number"
              id="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className={`mt-1 block w-full rounded-md border-0 py-1.5 pr-10  ring-1 ring-insering-gray-300 placeholder:text-gray-300 text-gray-900 focus:ring-gray-500 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ring-gray-500`}
            />
            {/* a user role selection menu */}
            {!isCurrentUser && 
                <>
                    <label
                    htmlFor="role"
                    className='block text-sm font-medium text-gray-700 mt-4'
                    >使用者權限:</label>
                    <select
                    id="role"
                    className={`mt-1 block w-full rounded-md border-0 py-1.5 pr-10  ring-1 ring-insering-gray-300 placeholder:text-gray-300 text-gray-900 focus:ring-gray-500 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ring-gray-500`}
                    required
                    onChange={(e) => setUserRole(e.target.value)}
                    value={userRole}
                    >
                        <option value="">請選擇</option>
                        <option value="admin">管理員</option>
                        <option value="member">用戶</option>
                        <option value="viewer">檢視者</option>
                    </select>
                </>
            }

        </>
    )
  }