import { PaperClipIcon } from '@heroicons/react/20/solid'
import ApplicationShell from '../../Components/ApplicationShell'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { getMessaging, getToken } from 'firebase/messaging'
import { useState, useEffect } from 'react'
import GlobalVars from '../../Config'

export default function UserProfile() {
    // set title
    document.title = "個人資料";

    const [idToken, setIdToken] = useState(null);

    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
        if (user) {
            // retrieve FCM token
            const messaging = getMessaging();
            getToken(messaging, { vapidKey: GlobalVars.firebase_publick_fcm_key }).then((currentToken) => {
                if (currentToken) {
                    console.log(currentToken);
                    setIdToken(currentToken);
                } else {
                    // Show permission request UI
                    console.log('No registration token available. Request permission to generate one.');
                }
            }).catch((err) => {
                console.log(err);
            });
        }
    });

    // grab users' profile information
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    useEffect(() => {
        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/users?userID=" + localStorage.getItem("userid");

        fetch(url, {
            headers: {
                "Authorization": sessionStorage.getItem("idToken")
            }
        }).then(response => response.json())
        .then(data => {
            setName(data['user']["displayName"]);
            setEmail(data['user']["meta"]["email"]);
        }).catch((error) => {
            console.log(error);
        });
    }, []);


    return (
        <>  
            <ApplicationShell>
                <div>
                    <div className="px-4 sm:px-0">
                        <h3 className="text-base font-semibold leading-7 text-gray-900">用戶資料</h3>
                        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">個人資料</p>
                    </div>
                    <div className="mt-6">
                        <dl className="grid grid-cols-1 sm:grid-cols-2">
                            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">顯示名稱</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{name}</dd>
                            </div>
                            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">電郵地址</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{email}</dd>
                            </div>

                            {/* <div className="border-t border-gray-100 px-4 py-6 sm:col-span-2 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">About</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur
                                qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia proident. Irure nostrud
                                pariatur mollit ad adipisicing reprehenderit deserunt qui eu.
                                </dd>
                            </div> */}
                            {/* <div className="border-t border-gray-100 px-4 py-6 sm:col-span-2 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Attachments</dt>
                                <dd className="mt-2 text-sm text-gray-900">
                                <ul role="list" className="divide-y divide-gray-100 rounded-md border border-gray-200">
                                    <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                    <div className="flex w-0 flex-1 items-center">
                                        <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                        <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                        <span className="truncate font-medium">resume_back_end_developer.pdf</span>
                                        <span className="flex-shrink-0 text-gray-400">2.4mb</span>
                                        </div>
                                    </div>
                                    <div className="ml-4 flex-shrink-0">
                                        <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                                        Download
                                        </a>
                                    </div>
                                    </li>
                                    <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                    <div className="flex w-0 flex-1 items-center">
                                        <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                        <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                        <span className="truncate font-medium">coverletter_back_end_developer.pdf</span>
                                        <span className="flex-shrink-0 text-gray-400">4.5mb</span>
                                        </div>
                                    </div>
                                    <div className="ml-4 flex-shrink-0">
                                        <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                                        Download
                                        </a>
                                    </div>
                                    </li>
                                </ul>
                                </dd>
                            </div> */}
                        </dl>
                    </div>
                </div>
            </ApplicationShell>
        </>
        
    )
}
