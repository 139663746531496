import ApplicationShell from "../../Components/ApplicationShell"
import { useState, useEffect } from "react";
import GlobalVars from "../../Config";

var renderCount = 0;
export default function Attendance() {
    // title be 報到
    document.title = "報到";


    // prompt users to if they want to take attendance 
    useEffect(() => {
        renderCount++;
        if (renderCount <= 1) {
            if (window.confirm("是否要報到？")) {
                // if yes, create a post request to report userID
                let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/attendance";
                let body = {
                    "userID": localStorage.getItem("userid"),
                    "organizationID": sessionStorage.getItem("organizationId"),
                };
                let headers = {
                    "Content-Type": "application/json"
                };
                fetch(url, {
                    method: "POST",
                    body: JSON.stringify(body),
                    headers: headers
                }).then(response => {
                    if (response.status === 200) {
                        // if success, prompt users to go back to home page
                        alert("報到成功");
                        window.location.href = "/job-list";
                    } else {
                        // if failed, prompt users to try again
                        alert("報到失敗，請再試一次");
                        window.location.href = "/job-list";
                    }
                }).catch(error => {
                    console.log(error);
                });
            } else {
                // if no, redirect users to home page
                window.location.href = "/job-list";
            }
        }
    }, []);

    return (
        <>
            <ApplicationShell>

            </ApplicationShell>
        </>
    )
}