import { useState, useEffect } from "react";


export default function App() {

    // check for bluetooth support
    const [bluetoothSupported, setBluetoothSupported] = useState(false);
    useEffect(() => {
        if (navigator.bluetooth) {
            setBluetoothSupported(true);
            console.log("Bluetooth supported");
        }
    }, []);

    // get devices
    const [devices, setDevices] = useState([]);
    const handleGetDevices = async () => {
        if (!bluetoothSupported) return;

        let options ={
            acceptAllDevices: true
        }

        // Request the user's permission to access Bluetooth devices
        navigator.bluetooth.requestDevice(options)
        .then(device => {
            console.log(`Device selected: ${device.name}`);
            // You can also get more details from the device object
            // such as device.id, device.gatt.connected etc.
        })
        .catch(error => {
            console.error("Something went wrong while selecting a device!", error);
        });

    };

    return (
        <div>
        <h1>Bluetooth Testing</h1>
        <button 
            onClick={handleGetDevices}
            disabled={!bluetoothSupported}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
            Get Devices
        </button>
        <ul>
            {devices.map(device => (
                <li key={device.id}>{device.name}</li>
            ))}
        </ul>
        </div>
    );
}