import {
    Combobox,
    Dialog,
    Transition,
  } from '@headlessui/react'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { FaceFrownIcon, GlobeAmericasIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import GlobalVars from '../../Config'
  

  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  
  export default function SearchPalettes({open, setOpen}) {
    const [query, setQuery] = useState('')

    const [items, setItems] = useState([
        { id: 1, name: 'Workflow Inc.', category: 'Clients', url: '#' },
        // More items...)
      ])
    
    useEffect(() => {
        // return if query is empty
        if(query === '') return;

        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/search?query=" + query + "&organizationID=" + localStorage.getItem("organizationId");
        fetch(url, {
          headers: {
            "Authorization": sessionStorage.getItem("idToken")
          }
        })
        .then(response => {
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            return response.json()
        })
        .then(data => {
            console.log(data);
            // change category name Location to 地點和工作類別, User to 用戶
            data['result'].forEach((item) => {
                if(item.category === "Location") {
                    item.category = "地點";
                } else if(item.category === "User") {
                    item.category = "用戶";
                } else if (item.category === "Job Type") {
                    item.category = "工作類別";
                }
            })  
            setItems(data['result']);
        })
        .catch(error => {
            console.error('There has been a problem with your fetch operation:', error);
        });


    }, [query])
  
    const filteredItems =
      query === ''
        ? []
        : items.filter((item) => {
            return item.name.toLowerCase().includes(query.toLowerCase())
          })
  
    const groups = filteredItems.reduce((groups, item) => {
      return { ...groups, [item.category]: [...(groups[item.category] || []), item] }
    }, {})
  
    return (
      <Transition show={open} afterLeave={() => setQuery('')} appear>
        <Dialog className="relative z-50" onClose={setOpen}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>
  
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-xl transform overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Combobox onChange={(item) => {
                    if (item === null) return;
                    window.location = item.url
                  }}>
                  <div className="relative">
                    <MagnifyingGlassIcon
                      className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <Combobox.Input
                      autoFocus
                      className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                      placeholder="Search..."
                      onChange={(event) => setQuery(event.target.value)}
                    //   onBlur={() => setQuery('')}
                    />
                  </div>
  
                  {query === '' && (
                    <div className="border-t border-gray-100 px-6 py-14 text-center text-sm sm:px-14">
                      <GlobeAmericasIcon className="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                      <p className="mt-4 font-semibold text-gray-900">搜尋用戶, 地點和工作類別</p>
                      <p className="mt-2 text-gray-500">
                        輸入關鍵字以搜尋用戶, 地點和工作類別
                      </p>
                    </div>
                  )}
  
                  {filteredItems.length > 0 && (
                    <Combobox.Options
                      static
                      as="ul"
                      className="max-h-80 scroll-pb-2 scroll-pt-11 space-y-2 overflow-y-auto pb-2"
                    >
                      {Object.entries(groups).map(([category, items]) => (
                        <li key={category}>
                          <h2 className="bg-gray-100 px-4 py-2.5 text-xs font-semibold text-gray-900">{category}</h2>
                          <ul className="mt-2 text-sm text-gray-800">
                            {items.map((item) => (
                              <Combobox.Option
                                key={item.id}
                                value={item}
                                className={({ focus }) =>
                                  classNames('cursor-pointer select-none px-4 py-2 hover:bg-gray-100 duration-300', focus && 'bg-indigo-600 text-white')
                                }
                              >
                                <div
                                    
                                >
                                {item.name}
                                </div>
                                
                              </Combobox.Option>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </Combobox.Options>
                  )}
  
                  {query !== '' && filteredItems.length === 0 && (
                    <div className="border-t border-gray-100 px-6 py-14 text-center text-sm sm:px-14">
                      <FaceFrownIcon className="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                      <p className="mt-4 font-semibold text-gray-900">No results found</p>
                      <p className="mt-2 text-gray-500">We couldn’t find anything with that term. Please try again.</p>
                    </div>
                  )}
                </Combobox>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    )
  }
  