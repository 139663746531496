import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { createContext, useEffect, useState } from 'react';
import FullJobList from './Pages/FullJobList';
import JobDetail from './Pages/JobDetail';
import Authentication from './Pages/Authentication';
import CreateAccount from './Pages/CreateAccount/CreateAccount';
import Signout from './Pages/Signout';
import ForgotPassword from './Pages/ForgotPassword';
import MyJobsList from './Pages/MyJobs/MyJobs';
import UserList from './Pages/UserList/UserList';
import LocationList from './Pages/LocationList/LocationList';
import UserProfile from './Pages/UserProfile';
import Dashboard from './Pages/Dashboard/Dashboard';
import Settings from './Pages/Settings';
import NFCJobProgress from './Pages/NFCJobProgress';
import Documents from './Pages/Documents';
import BluetoothTesting from './Pages/BluetoothTesting';
import TypeList from './Pages/TypeList';
import LeaveList from './Pages/LeaveList';
import Attendance from './Pages/Attendance/Attendance';
import AutoJobProgress from './Pages/AutoJobProgress/AutoJobProgress';
import IssueReporting from './Pages/IssueReporting';
import RecurringJob from './Pages/RecurringJob/RecurringJob';
import AutoJobProgressByLocation from './Pages/AutoJobProgressByLocation';
import AssistantList from './Pages/AssistantList/AssistantList';
import KnowledgeBase from './Pages/KnowledgeBase';
import CreateNewDocument from './Pages/CreateNewDocument';


import { fetchToken, onMessageListener } from './firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getMessaging, getToken } from 'firebase/messaging'
import GlobalVars from './Config'

import { UserContextProvider } from './Context';

// check if user is logged in
// function isLoggedIn() {
//   // check if user is logged in
//   if (sessionStorage.getItem("firebaseUid")) {
//     // remove users requested page from sessionStorage if exist
//     sessionStorage.removeItem("requestedPage");
//     return true;
//   } else {
//     // store users requested page in sessionStorage
//     sessionStorage.setItem("requestedPage", window.location.pathname);
//     return false;
//   }
// }


function App() {

  // check if users' current FCM token is stored in database. If true, store it in sessionStorage
  if (sessionStorage.getItem("fcmTokenStored") === null) {
    // skip if url contain 192.168
    if (window.location.href.includes("192.168")) {
      console.log("Skipping FCM token check...");
    } else {
      // retrieve FCM token
      const auth = getAuth();
      onAuthStateChanged(auth, (user) => {
          if (user) {
              // console.log('Requesting permission...');
              Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                  console.log('Notification permission granted.');
                  // retrieve FCM token
                  const messaging = getMessaging();
                  getToken(messaging, { vapidKey: GlobalVars.firebase_publick_fcm_key }).then((currentToken) => {
                      if (currentToken) {
                          
                          // create POST request to verify FCM token from /api/v1/users/fcm
                          let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/users/fcm";
                          let body = {
                              "fcmToken": currentToken,
                              "userID": localStorage.getItem("userid"),
                              "device": navigator.userAgent
                          };
                          let headers = {
                            "Content-Type": "application/json"
                          };

                          fetch(url, {
                              method: "POST",
                              body: JSON.stringify(body),
                              headers: headers
                          }).then(response => {
                              if (response.status === 200) {
                                  // store FCM token in sessionStorage
                                  sessionStorage.setItem("fcmTokenStored", true);
                              } else {
                                  console.log("Error: " + response.status);
                              }
                          });
                          
                      } else {
                          // Show permission request UI
                          console.log('No registration token available. Request permission to generate one.');
                      }
                  });
                }
              });
          }
      });
    }
  }


  // store users requested page in sessionStorage
  sessionStorage.setItem("requestedPage", window.location.pathname);

  // verify if users are logged in
  const isLoggedIn = sessionStorage.getItem("firebaseUid") ? true : false;



  return (
    <UserContextProvider>
      <Router>
        <div>
          <Routes>
            {/* public routes */}
            <Route path="/" element={isLoggedIn ? <Navigate to="/job-list" /> : <Navigate to="/authentication" />} />
            <Route path="/authentication" element={<Authentication />} />
            <Route path="/create-account" element={<CreateAccount />} />
            <Route path="/nfc-test" element={<NFCJobProgress />} /> {/* temporary route for testing NFC */}
            <Route path="/bluetooth-testing" element={<BluetoothTesting />} />

            {/* private routes */}
            <Route path="/job-list" element={isLoggedIn ? <FullJobList /> : <Navigate to="/authentication" />} />
            <Route path="/my-jobs" element={isLoggedIn ? <MyJobsList /> : <Navigate to="/authentication" />} />
            <Route path="/jobs/*" element={isLoggedIn ? <JobDetail /> : <Navigate to="/authentication" />} />
            <Route path="/signout" element={isLoggedIn ? <Signout /> : <Navigate to="/authentication" />} />
            <Route path="/forgot-password" element={isLoggedIn ? <ForgotPassword /> : <Navigate to="/authentication" />} />
            
              <Route path="/user-list" element={isLoggedIn ? <UserList /> : <Navigate to="/authentication" />} />
            
            <Route path="/location-list" element={isLoggedIn ? <LocationList /> : <Navigate to="/authentication" />} />
            <Route path="/user-profile" element={isLoggedIn ? <UserProfile /> : <Navigate to="/authentication" />} />
            <Route path="/dashboard" element={isLoggedIn ? <Dashboard /> : <Navigate to="/authentication" />} />
            <Route path="/settings" element={isLoggedIn ? <Settings /> : <Navigate to="/authentication" />} />
            <Route path="/nfc-job-progress" element={isLoggedIn ? <NFCJobProgress /> : <Navigate to="/authentication" />} />
            <Route path="/assistant-list" element={isLoggedIn ? <AssistantList /> : <Navigate to="/authentication" />} />
            <Route path="/knowledge-base" element={isLoggedIn ? <KnowledgeBase /> : <Navigate to="/authentication" />} />
            <Route path="/knowledge-base/create-new-document" element={isLoggedIn ? <CreateNewDocument /> : <Navigate to="/authentication" />} />

            <Route path="/documents" element={isLoggedIn ? <Documents /> : <Navigate to="/authentication" />} />
            <Route path="/type-list" element={isLoggedIn ? <TypeList /> : <Navigate to="/authentication" />} />
            <Route path="/leave-list" element={isLoggedIn ? <LeaveList /> : <Navigate to="/authentication" />} />
            <Route path="/attendance" element={isLoggedIn ? <Attendance /> : <Navigate to="/authentication" />} />
            <Route path="/issue-reporting" element={isLoggedIn ? <IssueReporting /> : <Navigate to="/authentication" />} />
            <Route path="/recurring-job" element={isLoggedIn ? <RecurringJob /> : <Navigate to="/authentication" />} />
            {/* URLs for NFC tags tied to specific job */}
            <Route path="/automatic-rule-progress/:tagid" element={isLoggedIn ? <AutoJobProgress /> : <Navigate to="/authentication" />} />
            <Route path="/automatic-rule-progress-by-location/:locid" element={isLoggedIn ? <AutoJobProgressByLocation /> : <Navigate to="/authentication" />} />

            {/* assistant */}
            

            {/* error page */}
            
          </Routes>
        </div>
      </Router>
    </UserContextProvider>
  );
}




export default App;