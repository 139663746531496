// handle signout 
import GlobalVars from "../../Config";
import { initializeApp } from "firebase/app";
import { getAuth, signOut } from "firebase/auth";
import { useState } from "react";

var confirmedSignout = false;
export default function Signout() {
  // initialize firebase app
  const firebaseApp = initializeApp(GlobalVars.firebase);

  // sign out
  const auth = getAuth(firebaseApp);
  const userId = localStorage.getItem("userid");
  const organizationID = localStorage.getItem("organizationId");
  if (confirmedSignout === false) {
    confirmedSignout = true;
    signOut(auth).then(() => {
      
      // clear sessionStorage
      sessionStorage.clear();

      // clear localStorage
      localStorage.clear();

      // return if userID or organizationID is null
      if (userId === null || organizationID === null) {
        window.location.href = "/authentication";
        return;
      }

      // check for users' current status
      fetch(GlobalVars.BACKEND_DOMAIN + "/api/v1/users/active-status?userID=" + userId + "&organizationID=" + organizationID)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error("Failed to retrieve user status");
        }
      }).then((data) => {
        if (data.status === "active") {
          const inactivate = window.confirm("是否要將您的狀態設為離線？");
          if (inactivate) {
            // create a fetch request to inactivate user status
            let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/users/active-status";
            let data = {
              "userID": userId,
              "status": "inactive",
              "organizationID": organizationID
            }

            // send request
            fetch(url, {
              method: "PUT",
              headers: {
                "Content-Type": "application/json"
              },
              body: JSON.stringify(data)
            }).then((response) => {
              return response.json();
            }
            ).then((data) => {
              // Sign-out successful.
              window.location.href = "/authentication";
            }).catch((error) => {
              console.log(error);
            });
          } else {
            // Sign-out successful.
            window.location.href = "/authentication";
          }
        }
      }).catch((error) => {
        console.log(error);
      })
      
    }).catch((error) => {
      // An error happened.
      alert("登出失敗，請重試。");
    });
  }
}