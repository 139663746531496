import GlobalVars from "../../Config";
import { initializeApp } from "firebase/app";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

export default function ForgotPassword() {
    // initialize firebase app
    const firebaseApp = initializeApp(GlobalVars.firebase);

    // send email to reset password
    const auth = getAuth(firebaseApp);
    const email = auth.email;
    sendPasswordResetEmail(auth, email)
        .then(() => {
            alert("已寄出重設密碼信件，請至信箱收取。");
        })
        .catch((error) => {
            alert("重設密碼失敗，請重試。");
        });

}