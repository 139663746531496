import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import GlobalVars from "../../Config";
import {
    CheckBadgeIcon,
    ArrowPathIcon,
    QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";

// progress job status with the given job id 
function ProgressJobStatus(jobid, setShowSuccess, redirect=true) {
    let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/jobs/progress?jobID=" + jobid;
    fetch(url, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            jobID: jobid,
            status: "完成",
            assignedTo: localStorage.getItem("userid"),
        }),
    
    })
    // if success, set show success to true
    .then((res) => {
        if (res.status === 200) {
            SuccessAction(setShowSuccess, jobid, redirect);
        } else {
            console.log(res);
        }
    
    })
    .catch((err) => {
        console.log(err);
    });
}

// sub job completion
function SubJobsCompletion(
    data,
    setShowSuccess,
) {
    // get locid from url (e.g. /automatic-rule-progress-by-location/66459e54b0a5ece209a16e2b)
    let locid = "";
    if (window.location.pathname.includes("/automatic-rule-progress-by-location/")) {
        locid = window.location.pathname.split("/automatic-rule-progress-by-location/")[1];
    }

    // update sublocation status using /api/v1/subjobs
    let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/subjobs";
    fetch(url, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            jobID: data._id,
            subJobs: {
                [locid]: true
            }
        }),
    })
        .then((res) => {
            if (res.status === 200) {
                // check if allSubTaskCompleted
                return res.json();
                
            } else {
                console.log(res);
            }
        })
        .then((subJobResp) => {
            if (subJobResp.hasOwnProperty('allSubTaskCompleted') && subJobResp.allSubTaskCompleted) {
                // if all subtask completed, progress the job status
                ProgressJobStatus(data._id, setShowSuccess);
            } else {
                SuccessAction(setShowSuccess, data._id);
            }
        })
        .catch((err) => {
            console.log(err);
        });
}


// main function
export default function AutoJobProgressByLocation() {
    // set document title to 地點自動進度
    document.title = "地點自動進度";

    // get loc id 
    let locid = "";
    if (window.location.pathname.includes("/automatic-rule-progress-by-location/")) {
        locid = window.location.pathname.split("/automatic-rule-progress-by-location/")[1];
    }

    // with a given locid, get associated jobid
    const [jobs, setJobs] = useState([]);
    const [jobId, setJobId] = useState("");
    const [jobProgressed, setJobProgressed] = useState(false);
    const [locationName, setLocationName] = useState("");
    useEffect(() => {
        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/jobs/retrieve-with-location?locationID=" + locid + "&userID=" + localStorage.getItem("userid");
        fetch(url)
            .then((res) => res.json())
            .then((rawData) => {
                

                // check if length of data is 0, if so, return
                if (rawData.message == "No jobs found") {
                    setJobs([]);
                    setLocationName(rawData.locationName);
                    return;
                } 

                let data = rawData.jobs;
                let updateList = [];
                // go through each job and check for sub jobs. If the sub job has been completed, remove it from the list
                data.forEach((job) => {
                    if (job.hasOwnProperty("subTaskProgress")) {
                        if (job.subTaskProgress.hasOwnProperty(locid) && !job.subTaskProgress[locid]) {
                            updateList.push(job);
                        }
                    } else {
                        updateList.push(job);
                    }
                });


                setJobs(updateList);
                setLocationName(rawData.locationName);

                // if there's only one job trigger the progress job status function
                if (updateList.length === 1) {
                    setJobId(updateList[0]._id);

                    // check if it's a job with multi location 
                    if (updateList[0].hasOwnProperty("locationIDs")) {
                        // check if there's multiple location in the job 
                        if (updateList[0].locationIDs.length > 1) {
                            SubJobsCompletion(updateList[0], setShowSuccess)
                        } else {    
                            // if there's only one location in the job, progress the job status
                            ProgressJobStatus(updateList[0]._id, setShowSuccess);
                        }
                    } else {
                        ProgressJobStatus(updateList[0]._id, setShowSuccess);
                    }
                
                    
                }

            });
    }, []);

    const [showSuccess, setShowSuccess] = useState(false);

   
    return (
        <div
            className="flex flex-col items-center justify-center min-h-screen py-2 px-4"
        >
            {jobs.length == 1 ? (
                <>  
                    <ArrowPathIcon className="w-6 h-6 inline-block animate-spin" />
                    <h1>找到 <span className="font-bold">{jobs.length}</span> 項工作</h1>
                    <p>現在為你更改工作狀態 </p>
                </>
            ) : (
                jobs.length > 1 ? (
                    <MultipleJobDisplay data={jobs} setShowSuccess={setShowSuccess} />
                ) : (
                    <>
                        <QuestionMarkCircleIcon className="w-16 h-16 text-gray-800 animate-pulse" />
                        <h1
                            className="text-center font-bold text-2xl mb-4"
                        >未不到工作</h1>
                        <p
                            className="text-center mb-1"
                        >請確認你今天在這個地點有工作</p>
                        <p>{locationName}</p>
                        <a
                            href="/job-list"
                            className="mt-10 text-center underline bg-gray-200 px-3 py-2 rounded-lg"
                        >回到工作列表</a>
                    </>
                )
            )}

            {showSuccess && (
                <div
                    className="fixed top-0 right-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 flex-col"
                >   
                    <CheckBadgeIcon className="w-16 h-16 text-green-500 animate-bounce" />
                    <h1 className="text-white">成功! 現在會帶你到工作詳細頁面</h1>
                    <button
                        onClick={() => {
                            window.location.href = jobId !== "" ? "/jobs/" + jobId : "#";
                        }}
                        className="mt-4 px-4 py-2 bg-green-500 text-white rounded-lg"
                    >
                        立即前往
                    </button>
                </div>
            )}
            
        </div>
    );
}


function MultipleJobDisplay({data, setShowSuccess}) {
    const handleJobProgress = (e, index, jobid, jobName, locationIDs, typeIDs) => { 
        // change button text to "執行中..."
        e.target.innerText = "執行中...";
        e.target.disabled = true;

        // prompt confirmation 
        if(!window.confirm("確定要執行這個工作嗎?\n" + "工作類別: " + jobName)) {
            return;
        }

        if (locationIDs.length > 1 || typeIDs.length > 1) {
            // if there's multiple location in the job, progress the sub job status
            SubJobsCompletion(data[index], setShowSuccess);
        } else {
            // fetch request to progress job status
            ProgressJobStatus(jobid, setShowSuccess, false);
        }
    }

    return (
        <>
            {/* list out all jobs with a button to execute each job */}
            <div className="mt-8 flow-root w-full max-w-[500px]">
                <h1
                    className="text-xl font-bold mb-4"
                >
                    發現{data.length} 項工作
                </h1>

                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                            <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                工作名稱
                            </th>
                            <th scope="col" className="py-3.5 pl-4  text-right text-sm font-semibold text-gray-900 sm:pl-0">
                                動作
                            </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                            {data.map((item, index) => (
                                <tr >
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                    {item.typeName}
                                    </td>
                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                    <button
                                        onClick={(e) => handleJobProgress(e, index, item._id, item.typeName, item.locationIDs, item.typeIDs)}    
                                        className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                                    >
                                        執行
                                    </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

// success action
const SuccessAction = (setShowSuccess, jobid, redirect=true) => {
    setShowSuccess(true);

    setTimeout(() => {
        // navigate users to the job list page
        if (redirect) {
            window.location.href = "/jobs/" + jobid;
        } else {
            setShowSuccess(false);
        }
        
    }, 2500);
}
