// This page is opened through scanning of a tag. The tagid is retrieved from the URL and used 
// to retrieve a related job request. The page will redirect users to the job request page if
// the job is found. Otherwise, users will be shown a error message saying no job is found.

// import useParams
import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import GlobalVars from "../../Config";


export default function AutoJobProgress() {
    document.title = "自動更改工作進度";

    const { tagid } = useParams();
    const [jobID, setJobID] = useState(null); // jobID is retrieved from the job request [tagid
    const [isLoading, setIsLoading] = useState(false);

    const handleUpdateProgress = (e) => {
        setIsLoading(true);

        // progress job by rules
        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/jobs/progress-by-rules";
        fetch(url, {
            method: "PUT",
            body: JSON.stringify({
                "ruleID": tagid,
                "assignedTo": localStorage.getItem("userid")
            }),
            headers: {
                "Content-Type": "application/json"
            }
        
        })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error("Failed to retrieve job details");
            }
        })
        .then(data => {
            alert("工作進度已更新。會把你轉到工作申請頁面。");
            setJobID(data.jobID);
            window.location.href = "/jobs/" + data.jobID;
        })
        .catch(error => {
            // show error message if job is not found
            console.log(error);
            setIsLoading(false);
            alert("找不到工作。會把你轉到工作申請頁面。")
            window.location.href = "/jobs";
        });
                 
    };

    return (
        <>
            {isLoading ? (
                // a loading spinner with ArrowPathIcon
                <div className="flex flex-col items-center justify-center h-screen">
                    <ArrowPathIcon className="w-10 h-10 animate-spin" />
                    <p>處理中...</p>
                </div>
            ) : (
                <>
                    <div className="flex flex-col items-center justify-center h-screen">
                        <p
                        className="text-xl font-bold text-center"
                        >確定要更新此工作進度嗎? Tag ID: {tagid}</p>
                        <button 
                        onClick={handleUpdateProgress}
                        className="duration-300 px-4 py-2 mt-4 text-white bg-indigo-500 rounded hover:bg-indigo-600"
                        >更新工作進度</button>
                    </div>
                </>
            )}
        </>
    );

}