import FullJobList from "../FullJobList/FullJobList";

export default function MyJobsList() {
    const userId = localStorage.getItem("userid");

    // set title to be 我的工作
    document.title = "我的工作"; 

    return (
        <FullJobList userid={userId} />
    )
}


