import ApplicationShell from "../../Components/ApplicationShell";
import { Fragment, useState, useEffect } from 'react'
import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon,
  MapPinIcon,
} from '@heroicons/react/20/solid'
import { Menu, Transition } from '@headlessui/react'
import SlideOvers from "../../Components/SlideOvers";
import GlobalVars from "../../Config";

const ReasonsMap = {
    "sick_leave": "病假",
    "annual_leave": "年假",
    "maternity_leave": "產假",
    "no_pay_leave": "無薪假",
}

export default function LeaveList() {
    // set document title
    useEffect(() => {
        document.title = "請假紀錄";
    }, []);

    // set sidebar open state
    const [sidebarOpen, setSidebarOpen] = useState(false);

    // handle form submission
    const handleSubmit = (event) => {
        // check if startDate is before endDate
        let startDate = document.getElementById("start-date").value;
        let endDate = document.getElementById("end-date").value;
        if (startDate > endDate) {
            alert("開始日期不可晚於結束日期");
            return;
        }

        // prompt users for confirmation
        if (window.confirm("確定要新增請假紀錄嗎？")) {
            // ensure users enter all required fields except remarks
            let reasons = document.getElementById("reasons").value;
            let startDate = document.getElementById("start-date").value;
            let endDate = document.getElementById("end-date").value;
            let halfDayLeave = document.getElementById("half-day-leave").value;

            if (reasons === "" || startDate === "" || endDate === "" || halfDayLeave === "") {
                alert("請填寫所有必填欄位" + "\n" + "請假原因、開始日期、結束日期");
                return;
            }

            let submitData = {
                "userID": localStorage.getItem("userid"),
                "startDate": startDate,
                "endDate": endDate,
                "reason": reasons,
                "type": halfDayLeave,
                "remarks": document.getElementById("remarks").value,
                "organizationID": sessionStorage.getItem("organizationId"),
            }
            
            // create post request to /api/v1/leaves
            let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/leaves";
            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(submitData)
            }).then(response => {
                if (response.status != 200) {
                    throw new Error("新增請假紀錄失敗");
                }
                return response.json()
            })
            .then(data => {
                alert("請假紀錄新增成功");
                window.location.reload();
                
            }).catch(error => {
                console.log(error);
                alert("請假紀錄新增失敗");
            });


        }
    }

    return (
        <>
            <ApplicationShell>
            <div className="md:flex md:items-center md:justify-between">
                <div className="min-w-0 flex-1">
                    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                        請假紀錄
                    </h2>
                </div>
                <div className="mt-4 flex md:ml-4 md:mt-0">
                    {/* <button
                    type="button"
                    className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                    Edit
                    </button> */}
                    <button
                    type="button"
                    className="ml-3 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => setSidebarOpen(true)}
                    >
                    新增請假
                    </button>
                </div>
            </div>
                <LeaveListTable setSidebarOpen={setSidebarOpen} />
            </ApplicationShell>
            <SlideOvers open={sidebarOpen} setOpen={setSidebarOpen} title={"新增請假"} content={<NewLeaveInputForm />} submitAction={handleSubmit} />
        </>
    )
}

// leave list table

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
function LeaveListTable() {
    const [fullMeetings, setFullMeetings] = useState([
        {
            id: 1,
            date: 'January 10th, 2022',
            time: '5:00 PM',
            datetime: '2022-01-10T17:00',
            name: 'Leslie Alexander',
            imageUrl:
              'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
            location: 'Starbucks',
          },
          // More meetings...
        ]);
    const [meetings, setMeetings] = useState([
        {
          id: 1,
          date: 'January 10th, 2022',
          time: '5:00 PM',
          datetime: '2022-01-10T17:00',
          name: 'Leslie Alexander',
          imageUrl:
            'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
          location: 'Starbucks',
        },
        // More meetings...
      ]);

    // retrieve existing leave records 
    useEffect(() => {
        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/leaves?organizationID=" + sessionStorage.getItem("organizationId");
        fetch(url, {
            method: "GET",
        }).then(response => {
            if (response.status != 200) {
                throw new Error("請假紀錄讀取失敗");
            }
            return response.json()
        })
        .then(rawData => {
            let data = rawData['leaves'];
            let meetings = [];

            // get today's date and set time to 00:00:00
            let today = new Date();
            today.setHours(0, 0, 0, 0);
            
            // iterate through each leave record and create a meeting object
            for (let i = 0; i < data.length; i++) {
                // skip if end date is before today
                let endDate = new Date(data[i]['endDate']);
                console.log(endDate);
                if (endDate <= today) {
                    continue;
                }

                // create meeting object and push to meetings array
                let meeting = {
                    id: i + 1,
                    date: data[i]['startDate'] + " 至 " + data[i]['endDate'].slice(5),
                    time: data[i]['type'] === "full-day" ? "全日" : data[i]['type'] === "morning" ? "早上" : "下午",
                    datetime: data[i]['startDate'],
                    name: data[i]['name'],
                    imageUrl: "",
                    location: ReasonsMap[data[i]['reason']],
                    meetingId: data[i]['_id'],
                }
                meetings.push(meeting);
            }
            setMeetings(meetings);
            setFullMeetings(meetings);
        }).catch(error => {
            console.log(error);
            alert("請假紀錄讀取失敗");
        });
    }, []);

    const [days, setDays] = useState([
        { date: '2021-12-27' },
        { date: '2021-12-28' },
        { date: '2021-12-29' },
        { date: '2021-12-30' },
        { date: '2021-12-31' },
        { date: '2022-01-01', isCurrentMonth: true },
        { date: '2022-01-02', isCurrentMonth: true },
        { date: '2022-01-03', isCurrentMonth: true },
        { date: '2022-01-04', isCurrentMonth: true },
        { date: '2022-01-05', isCurrentMonth: true },
        { date: '2022-01-06', isCurrentMonth: true },
        { date: '2022-01-07', isCurrentMonth: true },
        { date: '2022-01-08', isCurrentMonth: true },
        { date: '2022-01-09', isCurrentMonth: true },
        { date: '2022-01-10', isCurrentMonth: true },
        { date: '2022-01-11', isCurrentMonth: true },
        { date: '2022-01-12', isCurrentMonth: true, isToday: true },
        { date: '2022-01-13', isCurrentMonth: true },
        { date: '2022-01-14', isCurrentMonth: true },
        { date: '2022-01-15', isCurrentMonth: true },
        { date: '2022-01-16', isCurrentMonth: true },
        { date: '2022-01-17', isCurrentMonth: true },
        { date: '2022-01-18', isCurrentMonth: true },
        { date: '2022-01-19', isCurrentMonth: true },
        { date: '2022-01-20', isCurrentMonth: true },
        { date: '2022-01-21', isCurrentMonth: true },
        { date: '2022-01-22', isCurrentMonth: true, isSelected: true },
        { date: '2022-01-23', isCurrentMonth: true },
        { date: '2022-01-24', isCurrentMonth: true },
        { date: '2022-01-25', isCurrentMonth: true },
        { date: '2022-01-26', isCurrentMonth: true },
        { date: '2022-01-27', isCurrentMonth: true },
        { date: '2022-01-28', isCurrentMonth: true },
        { date: '2022-01-29', isCurrentMonth: true },
        { date: '2022-01-30', isCurrentMonth: true },
        { date: '2022-01-31', isCurrentMonth: true },
        { date: '2022-02-01' },
        { date: '2022-02-02' },
        { date: '2022-02-03' },
        { date: '2022-02-04' },
        { date: '2022-02-05' },
        { date: '2022-02-06' },
      ]);
    
    const [activeMonthYear, setActiveMonthYear] = useState({"month": 1, "year": 2023});

    // initial set days
    useEffect(() => {
        // get current month and year
        let today = new Date();
        let month = today.getMonth() + 1;
        let year = today.getFullYear();

        setActiveMonthYear({ "month": month, "year": year });
        GetDays(setDays, { "month": month, "year": year });
    }, []);

    // set days 
    // handle month increment
    function handleMonthIncrement() {
        let newMonth = activeMonthYear.month + 1;
        let newYear = activeMonthYear.year;
        if (newMonth > 12) {
            newMonth = 1;
            newYear += 1;
        }
        setActiveMonthYear({ "month": newMonth, "year": newYear });
        GetDays(setDays, { "month": newMonth, "year": newYear });
    }

    // handle month decrement
    function handleMonthDecrement() {
        let newMonth = activeMonthYear.month - 1;
        let newYear = activeMonthYear.year;
        if (newMonth < 1) {
            newMonth = 12;
            newYear -= 1;
        }
        setActiveMonthYear({ "month": newMonth, "year": newYear });
        GetDays(setDays, { "month": newMonth, "year": newYear });
    }

    // handle date deletion
    const handleDelete = (id) => {
        console.log(id)
        // prompt users confirmation
        if (!window.confirm("確定要刪除請假紀錄嗎？")) {
            return;
        }

        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/leaves?leaveID=" + id;
        fetch(url, {
            method: "DELETE",
        }).then(response => {
            if (response.status != 200) {
                throw new Error("刪除請假紀錄失敗");
            }
            return response.json()
        })
        .then(data => {
            alert("請假紀錄刪除成功, 現在為你重新整理頁面");
            window.location.reload();
        }).catch(error => {
            console.log(error);
            alert("請假紀錄刪除失敗");
        });
    }

    

    return (
        <div>
        {/* <h2 className="text-base font-semibold leading-6 text-gray-900">同事請假資料</h2> */}
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-16"

        
        >
            <div className="mt-10 text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 lg:mt-9 xl:col-start-9">
            <div className="flex items-center text-gray-900">
                <button
                type="button"
                className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                onClick={handleMonthDecrement}
                >
                <span className="sr-only">Previous month</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </button>
                <div className="flex-auto text-sm font-semibold">{activeMonthYear.year + "年 " + activeMonthYear.month + "月"}</div>
                <button
                type="button"
                className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                onClick={handleMonthIncrement}
                >
                <span className="sr-only">Next month</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </button>
            </div>
            <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
                <div>S</div>
                <div>M</div>
                <div>T</div>
                <div>W</div>
                <div>T</div>
                <div>F</div>
                <div>S</div>
            </div>
            <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
                {days.map((day, dayIdx) => (
                <button
                    key={day.date}
                    type="button"
                    className={classNames(
                    'py-1.5 hover:bg-gray-100 focus:z-10',
                    day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
                    (day.isSelected || day.isToday) && 'font-semibold',
                    day.isSelected && 'text-white',
                    !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900',
                    !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-400',
                    day.isToday && !day.isSelected && 'text-indigo-600',
                    dayIdx === 0 && 'rounded-tl-lg',
                    dayIdx === 6 && 'rounded-tr-lg',
                    dayIdx === days.length - 7 && 'rounded-bl-lg',
                    dayIdx === days.length - 1 && 'rounded-br-lg'
                    )}
                >
                    <time
                    dateTime={day.date}
                    className={classNames(
                        'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
                        day.isSelected && day.isToday && 'bg-indigo-600',
                        day.isSelected && !day.isToday && 'bg-gray-900'
                    )}
                    >
                    {day.date.split('-').pop().replace(/^0/, '')}
                    </time>
                </button>
                ))}
            </div>
            {/* <button
                type="button"
                className="mt-8 w-full rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
                Add event
            </button> */}
            </div>
            <ol className="mt-4 divide-y divide-gray-100 text-sm leading-6 lg:col-span-7 xl:col-span-8">
            {meetings.map((meeting) => (
                <li key={meeting.id} className="relative flex space-x-6 py-6 xl:static">
                {/* <img src={meeting.imageUrl} alt="" className="h-14 w-14 flex-none rounded-full" /> */}
                <div className="flex-auto">
                    <h3 className="pr-10 font-semibold text-gray-900 xl:pr-0">{meeting.name}</h3>
                    <dl className="mt-2 flex flex-col text-gray-500 xl:flex-row">
                    <div className="flex items-start space-x-3">
                        <dt className="mt-0.5">
                        <span className="sr-only">Date</span>
                        <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </dt>
                        <dd>
                        <time dateTime={meeting.datetime}>
                            {meeting.date} | {meeting.time}
                        </time>
                        </dd>
                    </div>
                    <div className="mt-2 flex items-start space-x-3 xl:ml-3.5 xl:mt-0 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                        <dt className="mt-0.5">
                        <span className="sr-only">Location</span>
                        <MapPinIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </dt>
                        <dd>{meeting.location}</dd>
                    </div>
                    </dl>
                </div>
                <Menu as="div" className="absolute right-0 top-6 xl:relative xl:right-auto xl:top-auto xl:self-center">
                    <div>
                    <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-gray-500 hover:text-gray-600">
                        <span className="sr-only">Open options</span>
                        <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
                    </Menu.Button>
                    </div>

                    <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                    >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                        {/* <Menu.Item>
                            {({ active }) => (
                            <a
                                href="#"
                                className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm'
                                )}
                            >
                                修改
                            </a>
                            )}
                        </Menu.Item> */}
                        <Menu.Item>
                            {({ active }) => (
                            <a
                                onClick={() => handleDelete(meeting.meetingId)}
                                className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm cursor-pointer'
                                )}
                            >
                                刪除
                            </a>
                            )}
                        </Menu.Item>
                        </div>
                    </Menu.Items>
                    </Transition>
                </Menu>
                </li>
            ))}
            </ol>
        </div>
        </div>
    )
}

// update current month with a given activeMonth by creating a days array where each week starts with a Sunday
function GetDays(setDays, activeMonthYear) {
    // get today's date
    let today = new Date();
    let todayDate = today.getDate();
    let todayMonth = today.getMonth() + 1;
    let todayYear = today.getFullYear();

    // parameter 
    let days = [];
    let month = activeMonthYear.month;
    let year = activeMonthYear.year;

    // get first day of the month
    let firstDay = new Date(year, month - 1, 1);
    let firstDayOfWeek = firstDay.getDay();

    // get last day of the month
    let lastDay = new Date(year, month, 0);
    let lastDayOfWeek = lastDay.getDay();
    let lastDate = lastDay.getDate();

    // based on the firstDayOfWeek, get the days of the previous month to fill the first week
    let prevMonth = month - 1;
    let prevYear = year;
    if (prevMonth < 1) {
        prevMonth = 12;
        prevYear -= 1;
    }
    let prevMonthLastDay = new Date(prevYear, prevMonth, 0);
    let prevMonthLastDate = prevMonthLastDay.getDate();
    let prevMonthDays = [];
    for (let i = 0; i < firstDayOfWeek; i++) {
        prevMonthDays.push(prevMonthLastDate - i);
    }
    prevMonthDays.reverse();
    for (let i = 0; i < prevMonthDays.length; i++) {
        days.push({ "date": prevYear + "-" + prevMonth + "-" + prevMonthDays[i] });
    }

    // get days of the current month
    for (let i = 0; i < lastDate; i++) {
        // check if the current day is today
        if (todayDate === i + 1 && todayMonth === month && todayYear === year) {
            days.push({ "date": year + "-" + month + "-" + (i + 1), "isCurrentMonth": true, "isToday": true });
        } else {
            days.push({ "date": year + "-" + month + "-" + (i + 1), "isCurrentMonth": true });
        }
        
    }

    // based on the lastDayOfWeek, get the days of the next month to fill the last week and skip if the last day of the month is a Sunday
    if (lastDayOfWeek !== 6) {
        let nextMonth = month + 1;
        let nextYear = year;
        if (nextMonth > 12) {
            nextMonth = 1;
            nextYear += 1;
        }
        let nextMonthDays = [];
        for (let i = 0; i < 6 - lastDayOfWeek; i++) {
            nextMonthDays.push(i + 1);
        }
        for (let i = 0; i < nextMonthDays.length; i++) {
            days.push({ "date": nextYear + "-" + nextMonth + "-" + nextMonthDays[i] });
        }
    }

    setDays(days);
    
}


// input form for creating new leaves
function NewLeaveInputForm() {
    

    // pre fill start date and end date as today
    let today = new Date();
    let todayDate = today.getDate();
    let todayMonth = today.getMonth() + 1;
    let todayYear = today.getFullYear();
    let todayString = todayYear + "-" + todayMonth + "-" + todayDate;

    const [startDate, setStartDate] = useState(todayString);
    const [endDate, setEndDate] = useState(todayString);

    return (
        <div className="flex flex-1 flex-col justify-between">
            <div className="space-y-6 pb-5 pt-6">
                <div>
                    <label
                        htmlFor="reasons"
                        className="block text-sm font-medium leading-6 text-gray-900"
                    >
                        原因
                    </label>
                    <div className="mt-2">
                        <select
                            name="reasons"
                            id="reasons"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        >
                            <option value="sick_leave">Sick Leave</option>
                            <option value="annual_leave">Annual Leave</option>
                            <option value="maternity_leave">Maternity Leave</option>
                            <option value="no_pay_leave">No Pay Leave</option>
                        </select>
                    </div>
                </div>
                <div>
                    <label
                    htmlFor="remarks"
                    className="block text-sm font-medium leading-6 text-gray-900"
                    >
                    補充
                    </label>
                    <div className="mt-2">
                    <textarea
                        type="text"
                        name="remarks"
                        id="remarks"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    </div>
                </div>
                <div>
                    <label
                    htmlFor="start-date"
                    className="block text-sm font-medium leading-6 text-gray-900"
                    >
                    開始日期
                    </label>
                    <div className="mt-2">
                    <input
                        type="date"
                        name="start-date"
                        id="start-date"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={startDate}
                        onChange={(event) => setStartDate(event.target.value)}
                    />
                    </div>
                </div>
                <div>
                    <label
                    htmlFor="start-date"
                    className="block text-sm font-medium leading-6 text-gray-900"
                    >
                    結束日期
                    </label>
                    <div className="mt-2">
                    <input
                        type="date"
                        name="end-date"
                        id="end-date"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={endDate}
                        onChange={(event) => setEndDate(event.target.value)}
                    />
                    </div>
                </div>
                <div>
                    <label
                        htmlFor="half-day-leave"
                        className="block text-sm font-medium leading-6 text-gray-900"
                    >
                        半日假
                    </label>
                    <div className="mt-2">
                        <select
                            name="half-day-leave"
                            id="half-day-leave"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        >
                            <option value="morning">早上</option>
                            <option value="afternoon">下午</option>
                            <option value="full-day" selected>全日</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    )
}
