import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import GlobalVars from "../../Config";

export default function FileViewer({attachments, selectedFile, setSelectedFile}) {
    const [open, setOpen] = useState(false);

    const [previewUrls, setPreviewUrls] = useState([])

    // check if selectedFile is not null, if so, open the modal
    useEffect(() => {
        if (selectedFile) {
            setOpen(true)

            // fetch request to get the preview urls and set it to previewUrls
            var url = GlobalVars.BACKEND_DOMAIN + "/api/v1/signed-url?fileID=" + selectedFile;
            fetch(url, {
                method: 'GET',
            }).then(response => response.json())
            .then(data => {
                // iterate through attachments to find the file name
                let fileName = "";
                for (var i = 0; i < attachments.length; i++) {
                    if (attachments[i]._id === selectedFile) {
                        fileName = attachments[i].fileName

                        break
                    }
                }

                // upload file to cloud storage
                setPreviewUrls([{
                    uri : data['url'],
                    fileName: fileName
                }]);
                
            }).catch((error) => {
                console.error('Error:', error);
            });
            
            
        }
    }, [selectedFile])

    // when users close the modal, set selectedFile to null
    useEffect(() => {
        if (!open) {
            setSelectedFile(null)
        }
    }, [open])

    return (
        <>
            {open &&
                <div 
                    className="fixed w-full h-full top-0 left-0 bg-gray-800 z-50"
                >
                    {/* toolbar */}
                    <div
                        className="h-[7vh] flex justify-between items-center px-3"
                    >
                        <button
                            onClick={() => setOpen(false)}
                        >
                            <XMarkIcon className="h-6 w-6 text-gray-300" aria-hidden="true" />
                        </button>
                    </div>
                    {/* file viewr */}
                    {previewUrls.length > 0 ?
                        <DocViewer 
                            documents={previewUrls} 
                            pluginRenderers={DocViewerRenderers} 
                        />
                    : <p>Loading Preview</p>
                    }

                </div>
            }
        </>
    )

}