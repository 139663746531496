import ApplicationShell from "../../Components/ApplicationShell/ApplicationShell";
import PageHeading from "../../Components/PageHeading/PageHeading";
import ChatInterface from "../../Components/ChatInterface";
import ChatInterfaceSlideOver from "../../Components/ChatInterfaceSlideOver";
import { useEffect, useState } from "react";
import GlobalVars from "../../Config";


const fetchConvo = ({setPastConversation}) => {
    fetch(GlobalVars.ASSISTANT_DOMAIN + "/api/v1/main/manage-conversation?userID=" + localStorage.getItem("userid"))
        .then(response => response.json())
        .then(data => {
            console.log(data);
            setPastConversation(data.response);
        });
}

export default function AssistantList() {
    // set title to be AI助手
    document.title = "AI助手";

    const [displayChatInterface, setDisplayChatInterface] = useState(false);
    const [msgId, setMsgId] = useState(null)

    const [pastConversation, setPastConversation] = useState([]);
    useEffect(() => {

        fetchConvo({setPastConversation});
    }, [])

    // refetch if msgId changes
    useEffect(() => {
        if (msgId) {
            fetchConvo({setPastConversation});
        }
    }, [msgId])

    return (
        <>
            <ApplicationShell>
                <PageHeading title="AI助手">
                    {/* <button
                        type="button"
                        className="ml-3 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        新增助手
                    </button> */}
                </PageHeading>
                <div
                    className="my-4"
                >
                    <ActionBar 
                        setDisplayChatInterface={setDisplayChatInterface}
                        setMsgId={setMsgId}
                    />
                </div>
                <PastConvoList 
                    convos={pastConversation} 
                    setDisplayChatInterface={setDisplayChatInterface}
                    setMsgId={setMsgId}
                />
                
            </ApplicationShell>
            {/* <ChatInterfaceSlideOver 
                open={displayChatInterface} 
                setOpen={setDisplayChatInterface} 
                msgId={msgId}
                setMsgId={setMsgId}
                setPastConversation={setPastConversation}
            /> */}
        </>
    );
}


// Action bar (新增助手, 查看助手)
function ActionBar({setDisplayChatInterface, setMsgId}) {
    return (
        <div
            className="flex justify-between items-center bg-indigo-200 p-2 rounded"
        >
            <div
                className="flex items-center"
            >
                <button
                    type="button"
                    className="ml-3 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => {
                        // set display chat interface to true
                        setDisplayChatInterface(true);
                        setMsgId(null);

                        // clear chat interface
                    }}
                >
                    新增助手
                </button>
            </div>
            <div
                className="flex items-center"
            >
                <button
                    type="button"
                    className="ml-3 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    查看助手
                </button>
            </div>
            
        </div>
    )
}

// past convo
function PastConvoList({convos, setDisplayChatInterface, setMsgId}) {
    const handleConvoClick = (convo) => {
        // set msg id
        setMsgId(convo._id);

        // set display chat interface to true
        setDisplayChatInterface(true);
    }

    return (
        <div
            className="grid grid-cols-1 gap-4"
        >
            {convos.map((convo) => (
                <div
                    key={convo._id}
                    onClick={() => handleConvoClick(convo)}
                    className="bg-white p-4 rounded shadow cursor-pointer hover:shadow-lg duration-300"
                >
                    <span>{convo.createdAt}</span>
                    <span
                        className="block  text-gray-600 text-sm"
                    >{convo.message}</span>
                </div>
            ))}
        </div>
    )
}
